import React from "react";
import { Nav } from "react-bootstrap";
import Header from "./Header";

export default class EventDetailHeader extends React.Component {
  render() {
    let bannerColor = this.props.activeTab === 3 ? "#CCAAF8" : this.props.activeTab === 2 ? "#6BDEFC" : this.props.activeTab === 1 ? "#FBD53D" : "#FF9A85";
 
    return (
      <>
        <Header>
          <Nav className="mr-auto custom-nav-item">
            {this.props.events &&
              this.props.events.map((element, index) => {
                const bgColor = this.props.activeTab === index + 1 ? bannerColor : "";
                if(this.props.activeTab == 1){
                  return (<></>)
                }
                return (
                  
                  <Nav.Link
                    key={index}
                    style={{
                      backgroundColor: bgColor
                    }}
                    className={this.props.activeTab === index + 1 ? "active f3" : "f3"}
                    onClick={() => this.props.changeActiveTab(index + 1)}>
                    {element.package_name}
                  </Nav.Link>
                );
              })}
          </Nav>
        </Header>
      </>
    );
  }
}
