import React, { Component } from "react";
import { Card } from "react-bootstrap";

export default class Summary extends Component {
  render() {
    const { price_details, participants } = this.props;
    let amount = (price_details.price * participants).toFixed(2);
    let gst = (price_details.price * participants * 0.18).toFixed(2);
    let gateway_fee = ((parseFloat(amount) + parseFloat(gst)) * 0.04).toFixed(2);
    let total = (parseFloat(amount) + parseFloat(gst) + parseFloat(gateway_fee)).toFixed(2);

    return (
      <>
        <p className="f3 b">Summary</p>
        <Card className="py-2" style={{ backgroundColor: "#E4E9F0", marginTop: "20px" }}>
          <p className="f4 b mb-0 px-3 uppercase">{price_details.package_name}</p>
          {/* <span className="f1 fd2 ticket-caption px-3">{price_details.ticket_description}</span> */}
          {/* {price_details.ticket_description} */}
          <hr />
          <div className="px-4">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>Participant</td>
                  <td className="float-right">
                    <h4 className="f5 b">{participants}</h4>
                  </td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td className="float-right f4 b">&#8377; {Number(amount).toLocaleString('en-IN')}</td>
                </tr>
                <tr>
                  <td>GST (18%)</td>
                  <td className="float-right f4 b">&#8377; {Number(gst).toLocaleString('en-IN')}</td>
                </tr>
                <tr>
                  <td>Gateway Fee</td>
                  <td className="float-right f4 b">&#8377; {Number(gateway_fee).toLocaleString('en-IN')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div className="px-4">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>Amount</td>
                  <td className="float-right">
                    <h4 className="f5 b">&#8377; {Number(total).toLocaleString('en-IN')}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      </>
    );
  }
}
