import React, { Component } from "react";
import EventDetailHeader from "../common/EventDetailHeader";

import { Row, Col, Container } from "react-bootstrap";
import BookingNavigation from "./BookingNavigation";
import TicketInfo from "./TicketInfo";
import AboutEvent from "./AboutEvent";
// import EventPrograms from "./EventPrograms";
import EventPrograms from './Workshops';
import Footer from "../common/Footer";
import Api from "../../server/Apis";
import EventDescription from "./EventDescription";
import { Loader } from "./../common/Loader";
import { Banner } from "./../common/Banner";

export default class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: [],
      activeTab: parseInt(this.props.match.params.package_id),
      pageLoaded: false
    };
  }
  componentDidMount() {
    fetch(Api.getEventDetails + "1")
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong ...");
        }
      })
      .then(data => {
        this.setState({ response: data, pageLoaded: true });
      })
      .catch(error => {
        console.log(error);
      });
  }
  changeTab = activeTab => {
    this.setState({ activeTab });
  };

  render() {
    const { event_packages, event_info, event_package_tickets, event_package_schedules } = this.state.response;
    let content;
    if (this.state.pageLoaded) {
      content = event_packages.map((el, index) => {
        if (this.state.activeTab === index + 1) {
          const tickets = event_package_tickets.filter((value, index) => {
            if (value.package_id === el.package_id) {
              return value;
            } else {
              return "";
            }
          });
          const workshops = event_package_schedules.filter((value, index) => {
            if (value.package_id === el.package_id) {

              return value;
            } else {
              return "";
            }
          });
          return (
            <div key={index}>
              <Banner activeTab={this.state.activeTab} name={el.package_name} description={el.package_description} />
              <Container>
                <EventDescription details={event_info} />
                {/* <Row className="mt-5">
                  <Col className="ml-5">
                    <h4 className="f5 b uppercase">{el.package_name}</h4>
                    <small className="f2">{el.package_description}</small>
                  </Col>
                </Row> */}
                {/* <BookingNavigation /> */}
                <TicketInfo tickets={tickets} event_id={event_info.event_id} package_id={el.package_id} />
                {/* <AboutEvent description={event_info.event_description} /> */}
                {/* {workshops[0].schedules.length > 0 && <EventPrograms workshops={workshops} />} */}
                {/* <EventPrograms /> */}
              </Container>
              <Footer />
            </div>
          );
        } else {
          return "";
        }
      });
    }
    return (
      <>
        {this.state.pageLoaded ? (
          <div className="main-content">
            <EventDetailHeader
              activeTab={this.state.activeTab}
              changeActiveTab={this.changeTab}
              events={event_packages}
            />
            {content}
          </div>
        ) : (
            <Loader />
          )}
      </>
    );
  }
}
