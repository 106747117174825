import React, { Component } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import moment from "moment";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
export default class Ticket extends Component {
  change = event => {
    this.props.selectedParticipants(this.props.ticket_details.ticket_id, event.target.value);
  };

  render() {

    let soldDisable = false;
    let disabledButton = true;
    if (this.props.total_active_tickets > 0) {
      disabledButton = false;
    }
    const {
      ticket_status,
      ticket_name,
      ticket_description,
      price,
      valid_from,
      current_available_count,
      valid_till
    } = this.props.ticket_details;
    const startDate = moment(valid_from, "YYYY-MM-DD").format("MMMM Do, YYYY");
    const endDate = moment(valid_till, "YYYY-MM-DD").format("MMMM Do, YYYY");
    // const end = moment(valid_till).format("YYYY-MM-DD")
    // const today = moment().format("YYYY-MM-DD")
    // const expired = moment(end).isBefore(today);
    // console.log("date", end, today, expired)

    console.log(typeof(price));
    let disabled = "mt-4";
    if (ticket_status !== 1 || current_available_count < 1) {
      disabled = "mt-4 custom_disabled";
    }
    let label = "";
    if (ticket_status === 1 && current_available_count > 0) {
      label = "On Sale";
    } else if (current_available_count < 1) {
      label = "Sold Out";
      soldDisable = true;
    }
    let options = [];
    for (let i = 1; i <= 10; i++) {
      options.push(
        <option value={i} key={i}>
          {i < 10 && 0}
          {i}
        </option>
      );
    }
    return (
      <>

        {ticket_status === 1 && <Card className={disabled}>
          <Card.Body className="px-5">
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>

                <span className={label === "On Sale" ? "badge badge-warning" : "badge  sold_out"}>{label}</span>
                <p className="font-weight-bold ticket-heading mb-0">

                  {ticket_name}
                </p>
                {/* <small className="ticket-caption">{ticket_description}</small> */}
                {/* <br /> */}
                <p className="mb-0 pb-0 f1" style={{ color: "#c74e4e" }}>
                  Exclusive Of GST 18%
              </p>
                {/* <small className="font-weight-bold mt-1">{ticket_status === 1 ? "Ends on " + endDate : "Starts on " + startDate}</small> */}
  
              <p className="mt-3" >
              <FontAwesomeIcon icon={faInfoCircle} size="lg" color="#007bff" />
                <small className="mt-1">  EMI options are available up to 24 months</small>
                </p>
              </Col>
              <Col xs={8} sm={8} md={6} lg={3}>
                <div>
                  <p className="ticket-caption mb-0 mt-4 ticket-amount">Amount</p>
                  <p className="font-weight-bold f5">&#8377; {price.toLocaleString('en-IN')}</p>
                </div>
              </Col>
              <Col xs={8} sm={8} md={6} lg={3}>

                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="par_box">
                      <Form.Row>
                        <Form.Group className="form-group" as={Col} controlId="formselectParticipant">
                          {this.props.disable_select_participant ? (
                            <Form.Control
                              className="form-control custom-disabled-label"
                              required
                              type="text"
                              disabled={true}
                              name="participants"
                              value={this.props.participants}
                              onChange={e => this.setState({ participants: e.target.value })}
                            />
                          ) : (
                              <Form.Control className={disabled} as="select" onChange={this.change.bind(this)}>
                                {ticket_status === 1 && current_available_count > 0 && <>{options}</>}
                              </Form.Control>
                            )}
                          <Form.Label className="form-control-placeholder">No. of participants</Form.Label>
                        </Form.Group>
                      </Form.Row>
                    </Form></Col>
                  {this.props.total_active_tickets > 1 &&
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="float-right mt-4" style={{ width: "100%" }}>
                        <NavLink to={"/select-participants/" + this.props.event_id + '/' + this.props.package_id + '/' + this.props.ticket_id + '/' + this.props.participants}>
                          <Button variant="primary" className="btn-custom f3 b ticket_b" disabled={soldDisable}>
                            Next
                </Button>
                        </NavLink>
                      </div>
                    </Col>
                  }
                </Row>

              </Col>
            </Row>
          </Card.Body>
        </Card>}
      </>

    );
  }
}
