import Moment from 'moment';

export const formatDate = (startDate, endDate) => {
    const start = Moment(new Date(startDate)).format("Do MMM");
    const end = Moment(new Date(endDate)).format("Do MMM YYYY");
    if (end !== start) {
        return start + " to " + end;
    }
    else {
        return start
    }
}

export const formatDate1 = (startDate, endDate) => {
    const start = Moment(new Date(startDate)).format("Do");
    const end = Moment(new Date(endDate)).format("Do MMMM, YYYY");
    if (end !== start) {
        return start + " & " + end;
    }
    else {
        return start
    }
}

export const formatDate2 = (startDate, endDate) => {
    const start = Moment(new Date(startDate)).format("Do");
    const end = Moment(new Date(endDate)).format("Do MMMM");
    if (end !== start) {
        return start + " & " + end;
    }
    else {
        return start
    }
}
export const formatDate3 = (startDate, endDate) => {
    const start = Moment(new Date(startDate)).format("Do");
    const end = Moment(new Date(endDate)).format("Do MMM");
    if (end !== start) {
        return start + ", " + end;
    }
    else {
        return start
    }
}
export const formatDate4 = (startDate, endDate) => {
    const start = Moment(new Date(startDate)).format("Do");
    const end = Moment(new Date(endDate)).format("Do MMM YYYY");
    if (end !== start) {
        return start + ", " + end;
    }
    else {
        return start
    }
}