import React, { Component } from "react";
// import umologo from "../../resources/images/umo-ticket.png";
import umologo from "../../resources/images/umo-logo-new.png";
import { Row, Col, Card } from "react-bootstrap";
import facebook from "../../resources/images/facebook.png";
import twitter from "../../resources/images/twitter.png";
import youtube from "../../resources/images/youtube.png";
import flicker from "../../resources/images/flicker.png";
import linkedin from "../../resources/images/linkedin.png";
// import uxindia from "../../resources/images/uxindia-logo.png";
import uxindia from "../../resources/images/uxindia-logo-new.png";
import { formatDate } from "./../common/Dates";

export default class EventDescription extends Component {
  render() {
    const {
      event_name,
      location,
      event_custom_url,
      contact_email,
      contact_number,
      organized_by,
      event_website,
      start_datetime,
      end_datetime,
      event_dates,
    } = this.props.details;

    const formattedDate = formatDate(start_datetime, end_datetime);

    return (
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className="mb-2">
        <h3 className=" b">{event_name}</h3>
          {/* <Card>
            <Card.Body>
              <div className="logo_in_description">
                <h3 className="uxindia-heading f5 b">{"UMO Design Grad 2024"}</h3>
                <img
                  src={uxindia}
                  alt="uxindia_logo"
                  style={{
                    height: 21 + "px",
                    marginLeft: 8 + "px",
                    marginTop: 8 + "px",
                  }}
                />
              </div>
              <br />
              <Col xs={12} md={10} lg={6} xl={5} className="ml-0 pl-0">
                <div className="uxindia-content ">
                  {location}
                  <p>{event_dates || formattedDate}</p>
                </div>
              </Col>
              <p className="mb-0">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={event_custom_url}
                >
                  {event_custom_url}
                </a>
                <br />
                <span className="uxindia-email">
                  {contact_email} | {contact_number}
                </span>
              </p>
            </Card.Body>
          </Card> */}
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* <Card>
            <Card.Body>
              <p className="f3 b">Share</p>
              <div className="social_icons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://goeventum.com/"
                  style={{
                    WebkitTransitionDelay: "0.05s",
                    transitionDelay: "0.05s",
                  }}
                >
                  <img
                    src={facebook}
                    alt="facebook"
                    style={{ width: 35 + "px" }}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/home?status=UXINIDA is hosting a India’s Biggest Int’l UX Conference on 28th, 29th & 30th Sep 2023. Check it Out here! https://goeventum.com/"
                  style={{
                    WebkitTransitionDelay: "0.05s",
                    transitionDelay: "0.05s",
                  }}
                >
                  <img
                    src={twitter}
                    alt="twitter"
                    style={{ width: 35 + "px" }}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/user/uxindia"
                >
                  <img
                    src={youtube}
                    alt="youtube"
                    style={{ width: 35 + "px" }}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.flickr.com/photos/uxindia"
                >
                  <img
                    src={flicker}
                    alt="flicker"
                    style={{ width: 35 + "px" }}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/groups/8338827/"
                >
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ width: 35 + "px" }}
                  />
                </a>
              </div>

              <br />
              <span className="f3 b">Organized by</span>
              <br />
              <img
                src={umologo}
                className="umo-logo mt-2 mb-3 "
                alt="umo Logo"
              />
              <p>{organized_by}</p>
              <a target="_blank" href={"https://" + event_website}>
                {event_website}
              </a>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
    );
  }
}
