import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

export default class ViewParticipant extends Component {
  render() {
    const { first_name, designation, org_name, email_id, phone_number, industry, address } = this.props.details;
    return (
        <Row className="f3">
          <Col>
            <p className=" b mb-0">{first_name}</p>
            <p className="mb-0">{designation}</p>
            <p className="mb-0">{email_id}, {phone_number}</p>
          </Col>
          <Col>
            <p className="b mb-0">{org_name}</p>
            <p className="mb-0">{industry}</p>
            <p className="mb-0">{address}</p>
          </Col>
        </Row>
    );
  }
}
