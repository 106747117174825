import React from "react";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

export default withRouter(
  class TopNav extends React.Component {
    render() {
      const toggleMenu = e => {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
      };

      return (
        <nav className="navbar navbar-light bg-light border-bottom">
          <FontAwesomeIcon icon={faBars} onClick={toggleMenu} id="menu-toggle" />

          <div className="top_nav_profile">
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0" style={{ float: "left", display: "inline-block" }}>
              <li
                style={{
                  display: "inline-block",
                  marginRight: "15px"
                }}>
                {/* <FontAwesomeIcon icon={faUserCircle} size="2x" /> */}
                <Link to="/">
                  <span className="logout">Home</span>
                </Link>
              </li>
              <li
                style={{
                  display: "inline-block"
                }}>
                {/* <FontAwesomeIcon icon={faUserCircle} size="2x" /> */}
                <span
                  className="logout"
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    console.log(this.props);
                    this.props.history.push("/");
                  }}>
                  Logout
                </span>
              </li>
            </ul>
          </div>
        </nav>
      );
    }
  }
);
