import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ticket_cover from "../../../resources/images/ticket_cover.png";
import { formatDate } from "../../common/Dates";
export default class AdminEvents extends Component {
  render() {
    return (
      <>
        <div className="dashboard_container">
          <Row className=" mt-3">
            <Col lg={12} md={12} xl={12}>
              <p className="f6 eb pl-1">My Events</p>
            </Col>


            <Row className="mt-4 mr-0 ml-3 pl-1" style={{ width: "100%" }}>
              {this.props.data.map((each, index) => {
                let soldTicket = each.max_quantity - each.current_available_count;
                let formattedDate = formatDate(each.start_datetime, each.end_datetime);
                return (
                  <Col
                    sm={8}
                    md={6}
                    lg={4}
                    xl={4}
                    className="px-0 mr-0 mb-3"
                    style={{ wordBreak: "break-all" }}
                    key={index}>
                    <Card className="mr-2">
                      <Card.Header className="ticket_cover" style={{ height: "150px" }}>
                        <img src={each.event_image} style={{ width: "100%", height: "150px" }} alt="ticket" />
                      </Card.Header>
                      <Card.Body className="card_body" style={{ height: "150px" }}>
                        <p className="mb-0 f2">{each.event_name}</p>
                        <p className="mb-0 f2">{formattedDate}</p>
                        <div className="tikcet_cover_grid">
                          <p className="b f2 mb-1">No. of Tickets sold:</p>
                          <p className="b f2 mb-1">{soldTicket}</p>
                          <p className="b f2 mb-1">Total Amount:</p>
                          <p className="b f2 mb-1">&#8377;&nbsp;{each.amount}</p>
                        </div>
                      </Card.Body>
                      <Card.Footer className="card_footer" style={{ height: "50px" }}>
                        <Row className="f2">
                          <Col md={5} xs={5} lg={5} xl={5}>
                            {/* <a target="_blank" className="tab_links" href="javascript:void(0);">
                                  Delete
                              </a> */}
                          </Col>

                          <Col md={3} xs={3} xl={3} lg={3}>
                            {/* <Link traget="_blank" className="tab_links" to="javascript:void(0);">
                                  Edit
                              </Link> */}
                          </Col>
                          <Col>
                            <Link className="tab_links" to={"/organization/dashboard/summary/" + each.event_id}>
                              Dashboard
                            </Link>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>
                );
              })}
              {/* <Col sm={8} md={6} lg={4} xl={4} className="px-0 mr-0 mb-3" >
                    <Card className="mr-2 py-0">


                      <div className="plus_circle">+</div>


                    </Card>
                  </Col> */}
            </Row>
            {/* )} */}
          </Row>
        </div>
      </>
    );
  }
}
