import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import EventDetails from "./components/eventDetails/EventDetails";
import Auth from "./components/auth/auth_main";
import SelectParticipants from "./components/selectParticipants/SelectParticipants";
import SetupPassword from "./components/auth/setupPassword";
import BookHotel from "./components/bookHotel/BookHotel";
import Payment from "./components/payment/Payment";
import GetTicket from "./components/getTicket/getTicket";
import EditParticipants from "./components/editParticipants/EditParticipants";
import EditHotel from "./components/editHotel/EditHotel";
import NotFoundError from "./components/common/NotFoundError";
import IndexRoute from "./components/IndexRoute";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import DashboardLayout from "./components/UserDashboard/DashboardLayout";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import { PrivacyPolicy } from "./Terms/PrivacyPolicy";
import { TermsConditions } from "./Terms/TermsConditions";
import { CodeOfConduct } from "./Terms/CodeOfConduct";
// import SelectWorkShops from "./components/selectWorkshops/SelectWorkshops";
import AdminDashboardLayout from './components/admin/dashboard/AdminDashboardLayout';

function App() {
  return (
    <>
      <NotificationContainer />
      <Router>
        <Switch>
          <Route exact path="/" component={IndexRoute} />
          <Route path="/dashboard" component={DashboardLayout} />
          <Route
            exact
            path="/event/:event_id/package/:package_id"
            component={EventDetails}
          />


          <Route path="/organization/dashboard" component={AdminDashboardLayout} />
          <Route
            path="/select-participants/:event_id/:package_id/:ticket_id/:participants"
            component={SelectParticipants}
            exact
          />
          <Route
            path="/reset_password/:reset_token"
            component={ResetPassword}
            exact
          />
          <Route
            path="/edit-participants/:booking_code"
            component={EditParticipants}
            exact
          />
          <Route
            path="/edit-hotel/:event_id/:booking_code"
            component={EditHotel}
            exact
          />
          {/* <Route path="/select-workshops/:event_id" component={SelectWorkShops} /> */}
          <Route path="/payment/:booking_code" component={Payment} exact />
          <Route
            path="/book-hotel/:event_id/:booking_code"
            component={BookHotel}
            exact
          />
          <Route path="/getticket/:booking_code" component={GetTicket} exact />
          <Route
            path="/getticket/:booking_code/:fromdashboard"
            component={GetTicket}
            exact
          />

          <Route path="/verify_email/:authToken" component={Auth} exact />
          <Route path="/auth" component={Auth} exact />
          <Route
            path="/set_password/:set_token"
            component={SetupPassword}
            exact
          />
          <Route path="/forgotPassword" component={ForgotPassword} exact />
          <Route path="/privacy-policy" component={PrivacyPolicy} exact />
          <Route path="/terms&conditions" component={TermsConditions} exact />
          <Route path="/codeofconduct" component={CodeOfConduct} exact />
          <Route path="*" exact={true} component={NotFoundError} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
