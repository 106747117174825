import React, { Component } from "react";
import Panel from "../common/Panel";
import { Form, Col, Container, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ViewBooking from "./ViewBooking";
import "react-datepicker";

export default class AddRoom extends Component {
  constructor(props) {
    super(props);
    let check_in = new Date(
        moment(this.props.event_details.start_datetime).subtract(1, "day")
      ),
      check_in_moment = moment(check_in).format("YYYY-MM-DD");
    this.state = {
      validated: false,
      room_type: "",
      // total_nights: moment(this.props.event_details.end_datetime, "YYYY-MM-DD").diff(
      //   moment(check_in_moment, "YYYY-MM-DD"),
      //   "days"
      // ),
      check_in: new Date(check_in),
      check_out: new Date(
        moment(this.props.event_details.end_datetime, "YYYY-MM-DD")
      ),
      guests_name1: "",
      double_occupancy: false,
      fillingDetails: true,
      details: {},
      checked: true,
      guests_name2: "",
      removable: true,
      editable: false,
      panel_title: "Add Room",
      total_nights: 0,
    };
  }

  componentDidMount() {
    let total_nights = moment(this.state.check_out, "YYYY-MM-DD").diff(
      moment(this.state.check_in, "YYYY-MM-DD"),
      "days"
    );
    this.setState({ total_nights: total_nights });
  }
  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let guests = [];
      let room_type = "Single Occupancy";
      guests.push(form.elements.guests_name1.value);
      if (form.elements.room_type.value === "2") {
        room_type = "Double Occupancy";
        guests.push(form.elements.guests_name2.value);
      }
      let data = {
        id: this.props.id,
        event_hotel_id: 1,
        room_type: parseInt(form.elements.room_type.value),
        total_nights: parseInt(form.elements.total_nights.value),

        check_in: form.elements.check_in.value,
        check_out: form.elements.check_out.value,

        guests_info: guests,
      };
      this.props.addRoom(data);
      this.setState({
        panel_title: "Room - " + room_type,
        validated: true,
        fillingDetails: false,
        details: data,
        removable: false,
        editable: true,
      });
    }
    this.setState({ validated: true });
  }
  changeRoomType = (e) => {
    if (e.target.value === "2") {
      this.setState({ double_occupancy: true });
    } else {
      this.setState({ double_occupancy: false });
    }
    this.setState({ room_type: e.target.value });
  };
  caluculateNightsByCheckOut = (event) => {
    let nights = moment(event, "YYYY-MM-DD").diff(
      moment(this.state.check_in, "YYYY-MM-DD"),
      "days"
    );
    this.setState({ check_out: event, total_nights: nights });
  };
  caluculateNightsByCheckIn = (event) => {
    let nights = moment(this.state.check_out, "YYYY-MM-DD").diff(
      moment(event, "YYYY-MM-DD"),
      "days"
    );
    this.setState({ check_in: event, total_nights: nights });
  };
  editPanel = (id) => {
    this.setState({
      validated: false,
      fillingDetails: true,
      removable: true,
      editable: false,
    });
  };
  render() {
    let check_in = new Date(
        moment(this.props.event_details.start_datetime).subtract(2, "day")
      ),
      check_in_moment = moment(check_in).format("YYYY-MM-DD");
    const max_checkout_date = moment(
      this.props.event_details.end_datetime,
      "YYYY-MM-DD"
    );
    const min_checkin_date = check_in_moment;
    const max_checkin_date = moment(
      this.state.check_out,
      "YYYY-MM-DD"
    ).subtract(1, "days");
    const min_checkout_date = moment(this.state.check_in, "YYYY-MM-DD").add(
      1,
      "days"
    );
    const { validated, double_occupancy, removable, editable } = this.state;
    const { attendees, id, collapsed, removePanel } = this.props;
    const guest_options = attendees.map((val, i) => {
      return (
        <option key={i} value={val.first_name}>
          {val.first_name}
        </option>
      );
    });
    return (
      <div className="mt-4">
        <Panel
          title={this.state.panel_title}
          removable={removable}
          id={id}
          removePanel={removePanel}
          collapsed={collapsed}
          editable={editable}
          editPanel={this.editPanel.bind(this)}
        >
          {this.state.fillingDetails ? (
            <Container>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => this.handleSubmit(e)}
                className="mt-3"
              >
                <Form.Row>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationOccupancy"}
                  >
                    <Form.Control
                      className="form-control"
                      as="select"
                      required
                      name="room_type"
                      defaultValue={this.state.room_type}
                      onChange={this.changeRoomType.bind(this)}
                    >
                      <option value="">Choose Occupancy</option>
                      <option value="1">Single</option>
                      <option value="2">Double</option>{" "}
                    </Form.Control>
                    <Form.Label className="form-control-placeholder">
                      Occupancy*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please Choose Occupancy.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationCustomNight"}
                  >
                    <Form.Control
                      className="form-control"
                      type="text"
                      required
                      name="total_nights"
                      value={this.state.total_nights + 1}
                      onChange={(e) =>
                        this.setState({ total_nights: e.target.value })
                      }
                      readOnly={true}
                    />
                    <Form.Label className="form-control-placeholder custom-disabled-label">
                      No. of nights*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please choose No. of nights.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="pt-0">
                  <Col className="form-group mt-1" lg={6} xl={6} md={6} sm={12}>
                    <DatePicker
                      required
                      selected={this.state.check_in}
                      placeholderText="Check-in"
                      dateFormat="YYYY-MM-dd"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      minDate={new Date(min_checkin_date)}
                      maxDate={new Date(max_checkin_date)}
                      dropdownMode="select"
                      className="custom_date"
                      name="check_in"
                      id={id + "validationCustomCheckIn"}
                      onChange={this.caluculateNightsByCheckIn.bind(this)}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: this.state.display_checkin }}
                    >
                      Please Choose Check-in Date.
                    </Form.Control.Feedback>
                  </Col>
                  <Col className="form-group mt-1" lg={6} xl={6} md={6} sm={12}>
                    <DatePicker
                      required
                      selected={this.state.check_out}
                      placeholderText="Check-out"
                      dateFormat="YYYY-MM-dd"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      maxDate={new Date(max_checkout_date)}
                      minDate={new Date(min_checkout_date)}
                      dropdownMode="select"
                      className="custom_date"
                      name="check_out"
                      id={id + "validationCustomCheckout"}
                      onChange={this.caluculateNightsByCheckOut.bind(this)}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: this.state.display }}
                    >
                      Please Choose Check-out Date.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationGuest"}
                  >
                    <Form.Control
                      className="form-control"
                      as="select"
                      required
                      name="guests_name1"
                      value={this.state.guests_name1}
                      onChange={(e) =>
                        this.setState({ guests_name1: e.target.value })
                      }
                    >
                      <option value="">Choose Guest</option>
                      {guest_options}
                    </Form.Control>
                    <Form.Label className="form-control-placeholder">
                      Guest 01*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please Choose Guest.
                    </Form.Control.Feedback>
                  </Form.Group>
                  {double_occupancy && (
                    <Form.Group
                      className="form-group"
                      as={Col}
                      md="6"
                      controlId={id + "validationGuest"}
                    >
                      <Form.Control
                        className="form-control"
                        as="select"
                        required
                        name="guests_name2"
                        value={this.state.guests_name2}
                        onChange={(e) =>
                          this.setState({ guests_name2: e.target.value })
                        }
                      >
                        <option value="">Choose Guest</option>
                        {guest_options}
                      </Form.Control>
                      <Form.Label className="form-control-placeholder">
                        Guest 02*
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        Please Choose Guest.
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Form.Row>

                <hr className="custom-hr" />
                <div className="float-right">
                  <Button
                    variant="primary"
                    // style={{ textDecoration: "none", color: "#3E6Ce2" }}
                    className="f2 b add_room_submit"
                    type="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form>
            </Container>
          ) : (
            <ViewBooking details={this.state.details} />
          )}
        </Panel>
      </div>
    );
  }
}
