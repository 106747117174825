import React from "react";
import Elite from "../../resources/images/elite.png";
import Premium from "../../resources/images/premium.png";
import Standard from "../../resources/images/standard.png";
import Banner2024 from "../../resources/images/Banner2024.png";

export const Banner = (props) => {
  let bannerColor =
    props.activeTab === 3
      ? "#CCAAF8"
      : props.activeTab === 2
      ? "#6BDEFC"
      : props.activeTab === 1
      ? "#FBD53D"
      : "#FF9A85";
  console.log(props.activeTab);
  if (props.activeTab == 1) {
    return (
      <div>
        <img className="bannerbackground" src={Banner2024} alt="banner" />
      </div>
    );
  } else
    return (
      <div className="banner" style={{ backgroundColor: bannerColor }}>
        <center className="banner_content">
          <div className="banner_image">
            <div className="inside_image">
              <img
                src={
                  props.activeTab === 3
                    ? Elite
                    : props.activeTab === 2
                    ? Premium
                    : Standard
                }
                alt="banner"
              />
            </div>
          </div>
          <div className="banner_detail">
            <p className="banner_name">{props.name}</p>
            <p className="banner_description b">{props.description}</p>
          </div>
        </center>
      </div>
    );
};
