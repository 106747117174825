import React, { Component } from "react";
import Panel from "../common/Panel";
import { Form, Col, Container, Button } from "react-bootstrap";
import ViewParticipant from "./ViewParticipant";
import DatePicker from "react-datepicker";
import PlacesAutoComplete from "react-places-autocomplete";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

export default class AddParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      fillingDetails: true,
      details: {},
      removable: true,
      editable: false,
      first_name: "",
      org_name: "",
      industry: "",
      designation: "",
      years_of_experience: "",
      date_of_birth: "",
      gender: "",
      email_id: "",
      phone_number: "",
      address: "",
      checked: false,
      collapsed: this.props.collapsed,
      panel_title: "Add Participant",
      show_message: false,
    };
  }

  onAddressChange = (e) => {
    this.setState({ address: e });
  };

  onAddressSelect = (address) => {
    this.setState({ address });
  };
  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ show_message: true });
    } else {
      this.setState({ show_message: false });
      let data = {
        id: this.props.id,
        first_name: form.elements.first_name.value,
        org_name: form.elements.org_name.value,
        industry: form.elements.industry.value,
        designation: form.elements.designation.value,
        years_of_experience: form.elements.years_of_experience.value,
        date_of_birth: this.state.date_of_birth || null,
        gender: form.elements.gender.value,
        email_id: form.elements.email_id.value,
        phone_number: form.elements.phone_number.value,
        address: this.state.address,
      };
      this.props.addParticipant(data);
      this.setState({
        panel_title: "Participant - " + data.first_name,
        validated: true,
        fillingDetails: false,
        details: data,
        removable: false,
        editable: true,
      });
    }
    this.setState({ validated: true });
  }
  editPanel = (id) => {
    this.setState({
      validated: false,
      fillingDetails: true,
      removable: true,
      editable: false,
      collapsed: false,
    });
  };

  render() {
    const label = (
      <>
        <span>
          The data that is collected will be used by the Organizer to plan and
          manage the event for which you registered, as well as email you
          relevant details about the event. By placing this order I also agree
          to the organizer’s
        </span>

        <a target="_blank" href="https://2023.ux-india.org/terms/">
          &nbsp;terms.
        </a>
      </>
    );
    const { validated, removable, editable, details, collapsed } = this.state;
    const { id, removePanel } = this.props;
    return (
      <div className="mt-2">
        <Panel
          title={this.state.panel_title}
          removable={removable}
          id={id}
          removePanel={removePanel}
          collapsed={collapsed}
          editable={editable}
          editPanel={this.editPanel.bind(this)}
        >
          {this.state.fillingDetails ? (
            <Container>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => this.handleSubmit(e)}
                className="mt-3"
              >
                <Form.Row>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationCustomEmail"}
                  >
                    <Form.Control
                      className="form-control"
                      required
                      type="email"
                      name="email_id"
                      value={this.state.email_id}
                      onChange={(e) =>
                        this.setState({ email_id: e.target.value })
                      }
                    />
                    <Form.Label className="form-control-placeholder">
                      Email Address*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Email Address
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationCustomMobile"}
                  >
                    <Form.Control
                      className="form-control"
                      type="text"
                      required
                      name="phone_number"
                      pattern="^\+{0,1}[0-9]{8,16}$"
                      value={this.state.phone_number}
                      onChange={(e) => {
                        this.setState({ phone_number: e.target.value });
                      }}
                    />
                    <Form.Label className="form-control-placeholder">
                      Mobile No.*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Enter valid mobile number
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="12"
                    controlId={id + "validationCustomName"}
                  >
                    <Form.Control
                      className="form-control"
                      required
                      type="text"
                      name="first_name"
                      pattern="^[^\s]{2,}.+$"
                      value={this.state.first_name}
                      onChange={(e) =>
                        this.setState({ first_name: e.target.value })
                      }
                    />
                    <Form.Label className="form-control-placeholder">
                      Full Name *
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Full Name
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationCustomOrgName"}
                  >
                    <Form.Control
                      className="form-control"
                      required
                      type="text"
                      name="org_name"
                      pattern="^[^\s]{1,}.+$"
                      value={this.state.org_name}
                      onChange={(e) =>
                        this.setState({ org_name: e.target.value })
                      }
                    />
                    <Form.Label className="form-control-placeholder">
                      University /Organisation*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Organisation Name
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationCustomIndustry"}
                  >
                    {/* <Form.Control
                      className="form-control"
                      type="text"
                      required
                      name="industry"
                      value={this.state.industry}
                      onChange={e => this.setState({ industry: e.target.value })}
                    /> */}

                    <Form.Control
                      className="form-control"
                      as="select"
                      required
                      name="industry"
                      defaultValue={this.state.industry}
                      onChange={(e) =>
                        this.setState({ industry: e.target.value })
                      }
                    >























Other
                      <option value="">Select</option>
                      <option value="Aerospace Engineering">Aerospace Engineering</option>
                      <option value="Agricultural Engineering">Agricultural Engineering</option>   
                      <option value="Applied Engineering">Applied Engineering</option>
                      <option value="Architecture">Architecture</option>
                      <option value="Automotive Engineering">Automotive Engineering</option>
                      <option value="Biomedical Engineering">Biomedical Engineering</option>
                      <option value="Ceramic Engineering">Ceramic Engineering</option>
                      <option value="Chemical Engineering">Chemical Engineering</option>
                      <option value="Civil Engineering">Civil Engineering</option>
                      <option value="Computer Engineering">Computer Engineering</option>
                      <option value="Electrical Engineering">Electrical Engineering</option>
                      <option value="Electronic Engineering">Electronic Engineering</option>
                      <option value="Energy Engineering">Energy Engineering</option>
                      <option value="Environmental Engineering">
                      Environmental Engineering
                      </option>
                      <option value="Fashion Design">Fashion Design</option>
                      <option value="Fine Arts">Fine Arts</option>
                      <option value="Interior Design">Interior Design</option>
                      <option value="Industrial Engineering">Industrial Engineering</option>
                      <option value="Information Engineering ">Information Engineering</option>
                      <option value="
Manufacturing Engineering">
Manufacturing Engineering</option>
                      <option value="Marine Engineering">Marine Engineering</option>
                      <option value="Mechanical Engineering">Mechanical Engineering</option>
                      <option value="Mining Engineering">Mining Engineering</option>
                      <option value="Petroleum Engineering">Petroleum Engineering</option>
                      <option value="
Structural Engineering">
Structural Engineering</option>
                      <option value="Management">Management</option>
                
                      <option value="Others">Others</option>
                    </Form.Control>
                    <Form.Label className="form-control-placeholder">
                      Branch*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please choose a Industry.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationCustomDesignation"}
                  >
                    <Form.Control
                      className="form-control"
                      required
                      type="text"
                      name="designation"
                      pattern="^[^\s]{1,}.+$"
                      value={this.state.designation}
                      onChange={(e) =>
                        this.setState({ designation: e.target.value })
                      }
                    />
                    <Form.Label className="form-control-placeholder">
                      Designation*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Designation
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationCustomYearsOfExp"}
                  >
                    <Form.Control
                      className="form-control"
                      placeholder="Linkedin Profile"
                      required
                      type="text"
                   
                      name="years_of_experience"
                      value={this.state.years_of_experience}
                      onChange={(e) =>
                        this.setState({ years_of_experience: e.target.value })
                      }
                    />
                    {/* <Form.Label className="form-control-placeholder">
                      Years of Experience*
                    </Form.Label> */}
                    {/* <Form.Control.Feedback type="invalid">
                      Please Enter Years of Experience.
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Col lg={6} xl={6} md={6} sm={12}>
                    <DatePicker
                      selected={this.state.date_of_birth}
                      placeholderText="Birth date"
                      dateFormat="dd/MM/yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="custom_date"
                      id="validationCustomDOB"
                      onChange={(date) =>
                        this.setState({ date_of_birth: date })
                      }
                      maxDate={new Date()}
                    />
                  </Col>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="6"
                    controlId={id + "validationCustomGender"}
                  >
                    <Form.Control
                      className="form-control"
                      as="select"
                      required
                      name="gender"
                      defaultValue={this.state.gender}
                      onChange={(e) =>
                        this.setState({ gender: e.target.value })
                      }
                    >
                      <option value="">Select</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="3">Other</option>
                    </Form.Control>
                    <Form.Label className="form-control-placeholder">
                      Gender*
                    </Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Please Choose Gender.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md="12"
                    controlId={id + "validationAddress"}
                  >
                    <PlacesAutoComplete
                      value={this.state.address}
                      onChange={this.onAddressChange}
                      onSelect={this.onAddressSelect}
                      style={{ position: "relative", display: "inline-block" }}
                      searchOptions={{
                        types: ["address"],
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              className: "form-control",
                              placeholder: "Address *",
                              // pattern: "^[^s]{1,}.+$",
                              pattern: ".*\\S.*",
                              required: true,
                            })}
                          />
                          {this.state.address && (
                            <Form.Label className="form-control-placeholder">
                              Address *
                            </Form.Label>
                          )}
                          <div
                            className="autocomplete-dropdown-container"
                            style={{
                              borderLeft:
                                suggestions.length > 0 &&
                                "1px solid rgba(3, 13, 43, 0.35)",
                              borderBottom:
                                suggestions.length > 0 &&
                                "1px solid rgba(3, 13, 43, 0.35)",
                              borderRight:
                                suggestions.length > 0 &&
                                "1px solid rgba(3, 13, 43, 0.35)",
                              position: "absolute",
                              zIndex: 99,
                              width: "96%",
                              borderRadius: "4px",
                            }}
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#f0f0f0",
                                    cursor: "pointer",
                                    padding: "1rem",
                                    fontSize: "12px",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: "1rem",
                                    fontSize: "12px",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutoComplete>

                    <Form.Control.Feedback type="invalid">
                      Please Enter Address
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Group>
                  <Form.Check
                    className="custom-label-checkbox"
                    required
                    label={label}
                    feedback="You must agree before submitting."
                    defaultChecked={this.state.checked}
                    onChange={(e) =>
                      this.setState({ checked: !this.state.checked })
                    }
                  />
                </Form.Group>
                <hr className="custom-hr" />
                <div className="float-right">
                  {this.state.show_message && (
                    <span className="f2 pb-2" style={{ color: "#dc3545" }}>
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                      ></FontAwesomeIcon>
                      &nbsp;&nbsp;Please fill in all mandatory
                      fields&emsp;&emsp;&emsp;
                    </span>
                  )}

                  <Button
                    variant="primary"
                    type="submit"
                    // style={{ textDecoration: "none", color: "#3E6Ce2" }}
                    className="f2 b add_participant_submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form>
            </Container>
          ) : (
            <ViewParticipant details={details} />
          )}
        </Panel>
      </div>
    );
  }
}
