import React from "react";
import { Row, Col } from "react-bootstrap";
import Header from "./Header";

export default class BookingHeader extends React.Component {
  render() {
    return (
      <>
        <Header>
          <Row className="" style={{ width: "100%" }}>
            <Col xs={12} sm={12} lg={12} md={12}>
              <div className="container_bar">
                {!this.props.fromDashboard && (
                  <ul className="progressbar">
                    <li className={this.props.progress === 0 ? "active" : this.props.progress > 0 ? "tick" : ""}>
                      Select Participants
                    </li>
                    {/* <li className={this.props.progress === 1 ? "active" : this.props.progress > 1 ? "tick" : ""}>
                      Select Hotel Rooms
                    </li> */}
                    <li className={this.props.progress === 2 ? "active" : this.props.progress > 2 ? "tick" : ""}>
                      Do payment
                    </li>
                    <li className={this.props.progress === 3 ? "active" : this.props.progress > 3 ? "tick" : ""}>
                      Get Ticket
                    </li>
                    {/* <li className={this.props.progress === 4 ? "active" : this.props.progress > 4 ? "tick" : ""}>
                      Workshops
                    </li> */}
                  </ul>
                )}
              </div>
            </Col>
          </Row>
        </Header>
      </>
    );
  }
}
