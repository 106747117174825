import React, { Component } from "react";
import { Navbar, Form } from "react-bootstrap";
// import logo from "../../resources/images/uxindia-logo.png";
import logo from "../../resources/images/uxindia-logo-new.png";
import { NavLink } from "react-router-dom";
// import UmoLogo from "../../resources/images/umo-logo.png";
import UmoLogo from "../../resources/images/umo-logo-new.png";
import $ from "jquery";
import "bootstrap";
import "react-bootstrap";
export default class Header extends Component {
  onItemsClick() {
    $("#basic-navbar-nav").removeClass("show");
  }
  state = {
    isVerified: false,
    user_type: 0,
  };
  componentDidMount() {
    if (localStorage.accessToken) {
      this.setState({ isVerified: true });
      if (!localStorage.user_type) {
        localStorage.removeItem("accessToken");
        this.setState({ isVerified: false });
      } else {
        this.setState({ user_type: localStorage.user_type });
      }
    }
  }
  render() {
    // const isVerified = localStorage.accessToken ? true : false;
    // const user_type = localStorage.user_type ? localStorage.user_type : 0;

    return (
      <>
        <Navbar bg="transparent" className="custom-nav" expand="lg" fixed="top">
          <Navbar.Brand
            href="https://www.umodesign.school/umodesign-grads"
            target="_blank"
            className="py-20"
          >
            <img src={UmoLogo} alt="Logo" className="header_sponser_logo" />
            <span className="vertical_divider"></span>
            <img
              src={logo}
              alt="Logo"
              className="header-logo header_platform_logo ml-0"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" onClick={this.onItemsClick}>
            {this.props.children}

            <Form inline>
              {this.state.isVerified && this.state.user_type === "3" && (
                <NavLink
                  to="/dashboard"
                  style={{ marginRight: "20px", width: "111px" }}
                >
                  My Dashboard
                </NavLink>
              )}
              {this.state.isVerified && this.state.user_type === "2" && (
                <NavLink
                  to="/organization/dashboard"
                  style={{ marginRight: "20px", width: "111px" }}
                >
                  My Dashboard
                </NavLink>
              )}
              {this.state.isVerified === false && (
                <NavLink to="/auth" style={{ marginRight: "50px" }}>
                  Login
                </NavLink>
              )}
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
