import React from "react";
import AuthHeader from './../components/common/AuthHeader';
export const TermsConditions = props => {
	return (
		<>
			<AuthHeader></AuthHeader>
			<div className="pt-5 terms_conditions" >

				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >Welcome
to GoEventum</span></b></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >These
				terms and conditions outline the rules and regulations for the use of
</span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >GoEventum's
Website.</span></b></font></font><font color="#333333"><font size="3"><span >
					</span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >GoEventum
website (&quot;</span></font></font><font color="#333333"><font size="3"><b><span >Website</span></b></font></font><font color="#333333"><font size="3"><span >&quot;)
										is an internet based event management portal owned and operated by
UiSER LLC (&quot;</span></font></font><font color="#333333"><font size="3"><b><span >Company</span></b></font></font><font color="#333333"><font size="3"><span >&quot;
or &quot;</span></font></font><font color="#333333"><font size="3"><b><span >Us</span></b></font></font><font color="#333333"><font size="3"><span >&quot;
or &quot;</span></font></font><font color="#333333"><font size="3"><b><span >We</span></b></font></font><font color="#333333"><font size="3"><span >&quot;),
a company incorporated under the laws of USA. </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >	</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >By
				accessing this website we assume you accept these terms and
				conditions in full. Do not continue to use GoEventum's website if you
do not accept all of the terms and conditions stated on this page.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >The
				following terminology applies to these Terms and Conditions, Privacy
				Statement and Disclaimer Notice and any or all Agreements: &quot;Client&quot;,
				&quot;You&quot; and &quot;Your&quot; refers to you, the person
				accessing this website and accepting the Company's terms and
				conditions. &quot;The Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;,
				&quot;Our&quot; and &quot;Us&quot;, refers to our Company. &quot;Party&quot;,
				&quot;Parties&quot;, or &quot;Us&quot;, refers to both the Client and
ourselves, or either the Client</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >or
				ourselves. All terms refer to the offer, acceptance and consideration
				of payment necessary to undertake the process of our assistance to
				the Client in the most appropriate manner, whether by formal meetings
				of a fixed duration, or any other means, for the express purpose of
				meeting the Client's needs in respect of provision of the Company's
				stated services/products, in accordance with and subject to,
prevailing law of . </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Any
				use of the above terminology or other words in the singular, plural,
				capitalisation and/or he/she or they, are taken as interchangeable
and therefore as referring to same.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >Cookies
</span></b></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >We
				employ the use of cookies. By using GoEventum's website you consent
				to the use of cookies in accordance with GoEventum's privacy policy.
				Most of the modern day interactive web sites use cookies to enable us
				to retrieve user details for each visit. Cookies are used in some
				areas of our site to enable the functionality of this area and ease
				of use for those people visiting. Some of our affiliate / advertising
partners may also use cookies. </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >License</span></b></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Unless
				otherwise stated, GoEventum and/or it's licensors own the
				intellectual property rights for all material on GoEventum. All
				intellectual property rights are reserved. You may view and/or print
				pages from https://www.goeventum.com for your own personal use
subject to restrictions set in these terms and conditions.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >You
must not:</span></b></font></font><font color="#333333"><font size="3"><span >	</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Republish
material from https://www.goeventum.com</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Sell,
rent or sub-license material from https://www.goeventum.com</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Reproduce,
duplicate or copy material from https://www.goeventum.com	</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Redistribute
				content from GoEventum (unless content is specifically made for
redistribution)</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >Hyperlinking
to our Content</span></b></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >	</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >The
				following organizations may link to our Web site without prior
written approval:</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >			</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Government
agencies;</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Search
engines;</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >News
organizations;</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Online
				directory distributors when they list us in the directory may link to
				our Web site in the same manner as they hyperlink to the Web sites of
other listed businesses; and</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Systemwide
				Accredited Businesses except soliciting non-profit organizations,
				charity shopping malls, and charity fundraising groups which may not
hyperlink to our Web site.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >			</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >These
				organizations may link to our home page, to publications or to other
				Web site information so long as the link: (a) is not in any way
				misleading; (b) does not falsely imply sponsorship, endorsement or
				approval of the linking party and its products or services; and (c)
fits within the context of the linking party's site.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >		</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >We
				may consider and approve in our sole discretion other link requests
from the following types of organizations: </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >commonly-known
				consumer and/or business information sources such as Chambers of
Commerce, American Automobile Association, AARP and Consumers Union;</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >dot.com
				community sites; associations or other groups representing charities,
				including charity giving sites, online directory distributors;
internet portals;</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >accounting,
				law and consulting firms whose primary clients are businesses; and
educational institutions and trade associations.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >			</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >		</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >	</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >We
				will approve link requests from these organizations if we determine
that: <br />(a) the link would not reflect unfavorably on us or our
										accredited businesses (for example, trade associations or other
										organizations representing inherently suspect types of business, such
as work-at-home opportunities, shall not be allowed to link); <br />(b)the
organization does not have an unsatisfactory record with us; </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >(c)
				the benefit to us from the visibility associated with the hyperlink
outweighs the absence of ; and </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >(d)
				where the link is in the context of general resource information or
				is otherwise consistent with editorial content in a newsletter or
similar product furthering the mission of the organization.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >These
				organizations may link to our home page, to publications or to other
Web site information so long as the link: </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >(a)
is not in any way misleading; </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >(b)
				does not falsely imply sponsorship, endorsement or approval of the
linking party and it products or services; and </span></font></font>
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >(c)
fits within the context of the linking party's site.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >If
				you are among the organizations listed in paragraph 2 above and are
				interested in linking to our website, you must notify us by sending
an e-mail to support@goeventum.com.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Please
				include your name, your organization name, contact information (such
				as a phone number and/or e-mail address) as well as the URL of your
				site, a list of any URLs from which you intend to link to our Web
				site, and a list of the URL(s) on our site to which you would like to
link. Allow 2-3 weeks for a response.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Approved
organizations may hyperlink to our Web site as follows:</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >		By
use of our corporate name; or</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >		By
use of the uniform resource locator (Web address) being linked to; or</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >		By
				use of any other description of our Web site or material being linked
				to that makes sense within the context and format of content on the
linking party's site.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >	</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >No
				use of GoEventum's logo or other artwork will be allowed for linking
absent a trademark license agreement.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Iframes
				Without prior approval and express written permission, you may not
				create frames around our Web pages or use other techniques that alter
in any way the visual presentation or appearance of our Web site.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >Reservation
of Rights</span></b></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >We
				reserve the right at any time and in its sole discretion to request
				that you remove all links or any particular link to our Web site. You
				agree to immediately remove all links to our Web site upon such
				request. We also reserve the right to amend these terms and
				conditions and its linking policy at any time. By continuing to link
				to our Web site, you agree to be bound to and abide by these linking
terms and conditions.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >Removal
of links from our website</span></b></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >If
				you find any link on our Web site or any linked web site
				objectionable for any reason, you may contact us about this. We will
				consider requests to remove links but will have no obligation to do
so or to respond directly to you.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >Whilst
				we endeavour to ensure that the information on this website is
				correct, we do not warrant its completeness or accuracy; nor do we
				commit to ensuring that the website remains available or that the
material on the website is kept up to date.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >Content
Liability</span></b></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >We
				shall have no responsibility or liability for any content appearing
				on your Web site. You agree to indemnify and defend us against all
				claims arising out of or based upon your Website. No link(s) may
				appear on any page on your Web site or within any context containing
				content or materials that may be interpreted as libelous, obscene or
				criminal, or which infringes, otherwise violates, or advocates the
infringement or other violation of, any third party rights.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><b><span >Disclaimer</span></b></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >To
				the maximum extent permitted by applicable law, we exclude all
				representations, warranties and conditions relating to our website
				and the use of this website (including, without limitation, any
				warranties implied by law in respect of satisfactory quality, fitness
				for purpose and/or the use of reasonable care and skill). Nothing in
this disclaimer will:</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >limit
				or exclude our or your liability for death or personal injury
resulting from negligence;</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >limit
				or exclude our or your liability for fraud or fraudulent
misrepresentation;</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >limit
				any of our or your liabilities in any way that is not permitted under
applicable law; or</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >exclude
				any of our or your liabilities that may not be excluded under
applicable law.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >	</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >The
				limitations and exclusions of liability set out in this Section and
elsewhere in this disclaimer: </span></font></font>
				</p>
				<ol type="a">
					<li><p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >are
	subject to the preceding paragraph; and </span></font></font>
					</p>
					</li>
					<li><p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >govern
					all liabilities arising under the disclaimer or in relation to the
					subject matter of this disclaimer, including liabilities arising in
					contract, in tort (including negligence) and for breach of statutory
					duty. To the extent that the website and the information and
	services on the website are provided free of charge,</span></font></font></p></li>
				</ol>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >	we
will not be liable for any loss or damage of any nature.</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><font color="#333333"><font size="3"><span >	</span></font></font></p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>
				<p style={{ marginBottom: "0in" }}><br />
				</p>

			</div>
		</>
	);
};
