import React, { Component } from "react";
import axios from "../../server/axios";

import { HashRouter as Router, Route, Switch } from "react-router-dom";
import NotFoundError from "./../common/NotFoundError";
import MyEvents from "./MyEvents";
import UserSetting from "./UserSetting";
import { Sidebar } from "./Sidebar";
import TopNav from "./TopNav";
import { Loader } from "./../common/Loader";
import { NotificationManager } from "react-notifications";
import GetTicket from './dashboardTicket/getTicket';

export default class DashboardLayout extends Component {
  state = {
    verified: false,
    data: {},
    userData: {},
    hasEvent: false,
    eventData: []
  };
  componentDidMount() {
    axios({
      method: "GET",
      url: "/dashboard",
      headers: {
        "Access-Token": localStorage.accessToken
      }
    })
      .then(response => {
        console.log(response);

        if (response.data.message === "no_events_found") {
          this.setState({ hasEvent: false, verified: true });
        } else if (response.data.message === "success") {
          console.log(response.data);
          this.setState({
            data: response.data,
            hasEvent: true,
            userData: { name: response.data.name, email: response.data.email },
            eventData: response.data.tickets,
            verified: true
          });
        }
      })
      .catch(error => {
        console.log(error.response);
        if (error.response.data.message === "invalid_token") {
          localStorage.removeItem("accessToken");
          NotificationManager.error("session is expired", "Login again", 5000);
          this.props.history.push("/auth");
        }
      });
  }

  render() {
    return (
      <>
        {this.state.verified === false ? (
          <Loader />
        ) : (
            <div id="#page-content-wrapper">
              <div className="d-flex" id="wrapper">
                <Sidebar pathname={this.props.history.location.pathname} userData={this.state.userData} />
                <div id="page-content-wrapper">
                  <TopNav />
                  <div>
                    <Switch>
                      <Route
                        exact
                        path="/dashboard"
                        render={props => (
                          <MyEvents {...props} hasEvent={this.state.hasEvent} data={this.state.eventData} />
                        )}
                      />
                      <Route path="/dashboard/UserSetting" component={UserSetting} />
                      <Route
                        path="/dashboard/getticketDashboard/:booking_code/:fromdashboard"
                        component={GetTicket}
                        exact
                      />
                      {/* <Route
                        path="/dashboard/bookhotel/:booking_code"
                        component={NotFoundError}
                        exact
                      /> */}
                      <Route component={NotFoundError} />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}
