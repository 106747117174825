import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default class Message extends React.Component {
  onClose = () => {
    document.getElementById("message").style.display = "none";
  };

  render() {
    return (
      <div
        className={
          this.props.type === "success" ? "success_message pl-3  mt-3" : "success_message error_message pl-3  mt-3"
        }
        id="message">
        <div style={{ paddingTop: "12px", paddingBottom: "10px" }}>
          <b>{this.props.type === "success" ? "Success !" : "Oops !"} &nbsp;</b> {this.props.children}
        </div>
        <div className="message_close">
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            className={
              this.props.type === "success"
                ? "fa fa-times fa-lg close_icon"
                : "fa fa-times fa-lg close_icon error_close_icon"
            }
            onClick={this.onClose}
          />
        </div>
      </div>
    );
  }
}
