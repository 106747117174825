import React, { Component } from 'react'
import { Link } from "react-router-dom";

export default class AboutButton extends Component {
    render() {
        const {package_id, package_name, event_id} = this.props;
        return (
            <>
                <Link to={"/event/"+event_id+"/package/"+package_id} className="custom-link">
                    <p className="f3 fd1">&larr; About {package_name}</p>
                </Link>
            </>
        )
    }
}
