import React from "react";
// import GoogleLogin from "react-google-login";
import { Form, Col, Button } from "react-bootstrap";
import axios from "../../server/axios";
import Message from "./../common/Message";
import { Redirect, Link, withRouter } from "react-router-dom";
export default withRouter(class Login extends React.Component {
  state = {
    validated: false,
    loginClicked: false,
    email_id: "",
    password: "",
    responseType: "",
    responseMessage: "",
    redirect: false,
    user_type: 0,
  };
  onInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onLoginClick(event) {
    event.preventDefault();
    if (this.state.loginClicked === false) {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        this.setState({ loginClicked: false });
        event.stopPropagation();
      } else {
        axios({
          method: "POST",
          url: "/login",
          data: {
            email_id: this.state.email_id,
            password: this.state.password
          }
        })
          .then(response => {
            localStorage.setItem("accessToken", response.data.token);
            localStorage.setItem("user_type", response.data.user_type);

            this.setState({
              loginClicked: false,
              redirect: true,

            });

            if (response.data.user_type === 2) {
              this.props.history.push("/organization/dashboard")
            } else if (response.data.user_type === 3) {
              this.props.history.push("/dashboard")
            }
          })
          .catch(error => {
            console.log(error.response);
            this.setState({ loginClicked: false });

            if (error.response) {
              if (error.response.data) {
                if (error.response.data.code === 403) {
                  this.setState({ responseType: "error", responseMessage: error.response.data.message });
                }
              }
            }
          });
      }

      this.setState({ validated: true });
    }
  }
  render() {
    // const responseGoogle = response => {
    //   console.log(response);
    // };
    const { validated } = this.state;
    return (
      <>
        {/* {(this.state.redirect && this.state.user_type === 3) && <Redirect to="/dashboard" />}
        {(this.state.redirect && this.state.user_type === 2) && <Redirect to="/admin/dashboard" />} */}

        <h3 className="common-text_center f6 b pt-3 mt-5">Welcome back !</h3>
        <br />
        {this.state.responseType && <Message type={this.state.responseType}>{this.state.responseMessage}</Message>}
        {/* below commented code will be required in future  so dont delete this code
        
        */}
        {/* <br />
        <div className="auth_signIn_google">
          <GoogleLogin
            clientId="722957183643-n72btqd2e09nv3053b5p56nvcn8p2ek2.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
            buttonText="LOGIN WITH GOOGLE"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          />
        </div>

        <br />
        <br />

        <div className="common-text_center">
          <div className="line" />
          <div>
            <span className="line-midWord f3 fd1">Or</span>
          </div>
        </div>
        <br /> */}
        <br />

        <Form noValidate validated={validated} onSubmit={e => this.onLoginClick(e)} className="pb-5 mt-4">
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustomLEmail" className="form-group">
              <Form.Control
                required
                type="email"
                className="form-control"
                name="email_id"
                value={this.state.email_id}
                onChange={this.onInputChange}
              />
              <Form.Label className="form-control-placeholder">Email Address</Form.Label>
              <Form.Control.Feedback type="invalid">Please Enter Valid Email Address</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustomLPasword" className="form-group">
              <Form.Control
                required
                type="password"
                className="form-control"
                pattern="^((?!.*[\s])(?=.*[a-z A-Z])(?=.*\d).{8,})"
                name="password"
                value={this.state.password}
                onChange={this.onInputChange}
              />
              <Form.Label className="form-control-placeholder">Password</Form.Label>
              <Form.Control.Feedback type="invalid">Please Enter Valid Password</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Button
            disabled={this.props.forVerification && this.state.loginClicked}
            className="auth-primary mt-2 f3 b"
            size="lg"
            type="submit"
            block>
            LOG IN
          </Button>
          <br />
          <p className="f3 b auth-links_clicked" style={{ border: "none" }}>
            <Link to="/forgotPassword">I forgot my password</Link>
          </p>
        </Form>
      </>
    );
  }
})
