import React, { Component } from "react";
import { Col, Card, Form, Button } from "react-bootstrap";
import PlacesAutoComplete from "react-places-autocomplete";
export default class OrganisationDetails extends Component {
  constructor(props) {
    super(props);
    if (this.props.is_bill_for_org) {
      this.state = {
        checked: true,
        validated: false,
        billing_org_name: this.props.billing_info.billing_org_name,
        billing_gst_number: this.props.billing_info.billing_gst_number,
        billing_address: this.props.billing_info.billing_address
      };
    } else {
      this.state = {
        checked: false,
        validated: false,
        billing_org_name: "",
        billing_gst_number: "",
        billing_address: ""
      };
    }
  }
  onAddressChange = e => {
    this.setState({ billing_address: e });
  };

  onAddressSelect = billing_address => {
    this.setState({ billing_address });
  };
  handleSubmit(event) {
    event.preventDefault();
    console.log("1");
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      console.log("2");
      event.preventDefault();

      event.stopPropagation();
    }
    this.setState({ validated: true });
  }
  addOrganisationDetails = event => {
    this.setState({
      checked: !this.state.checked
    });
  };
  getOrganisationDetails = event => {
    if (this.state.checked === true) {
      document.getElementById("submit_btn").click();
    }
    return this.state;
  };
  render() {
    const { validated } = this.state;
    return (
      <>
        <Card>
          <Card.Body className="px-5 py-5">
            <Card className="organisation-card" style={{ marginLeft: "2rem", marginRight: "2rem" }}>
              <Card.Body className="px-4 py-3">
                <p className="font-weight-bolder">Invoice</p>
                <Form noValidate validated={validated} id="id" onSubmit={e => this.handleSubmit(e)}>
                  <Form.Group>
                    <Form.Check
                      required
                      className="invoice_check"
                      label="Raise invoice on my Organization or on My Name"
                      defaultChecked={this.state.checked}
                      onChange={this.addOrganisationDetails.bind(this)}
                    />
                  </Form.Group>
                  {this.state.checked && (
                    <>
                      <p className="font-weight-bolder">Organisation Details</p>
                      <Form.Row>
                        <Form.Group className="form-group" as={Col} md="6" controlId="validationCustomORGName">
                          <Form.Control
                            required
                            type="text"
                            className="form-control"
                            name="billing_org_name"
                            value={this.state.billing_org_name}
                            onChange={e => this.setState({ billing_org_name: e.target.value })}
                          />
                          <Form.Label className="form-control-placeholder">Organisation Name *</Form.Label>
                          <Form.Control.Feedback type="invalid">Please Enter Organisation Name</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group" as={Col} md="6" controlId="validationCustomGST">
                          <Form.Control
                            type="text"
                            className="form-control"
                            name="billing_gst_number"
                            value={this.state.billing_gst_number}
                            onChange={e => this.setState({ billing_gst_number: e.target.value })}
                          />
                          <Form.Label className="notRequired-placeholder">GST Number </Form.Label>
                          <Form.Control.Feedback type="invalid">Please Enter GST Number</Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group className="form-group" as={Col} md="12" controlId="validationCustomAddress">
                          <PlacesAutoComplete
                            value={this.state.billing_address}
                            onChange={this.onAddressChange}
                            onSelect={this.onAddressSelect}
                            style={{ position: "relative", display: "inline-block" }}
                            searchOptions={{
                              types: ["address"]
                            }}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    className: "form-control",
                                    placeholder: "Address *",
                                    pattern: ".*\\S.*",
                                    required: true
                                  })}
                                />
                                {this.state.address && (
                                  <Form.Label className="form-control-placeholder">Address *</Form.Label>
                                )}
                                <div
                                  className="autocomplete-dropdown-container"
                                  style={{
                                    borderLeft: suggestions.length > 0 && "1px solid rgba(3, 13, 43, 0.35)",
                                    borderBottom: suggestions.length > 0 && "1px solid rgba(3, 13, 43, 0.35)",
                                    borderRight: suggestions.length > 0 && "1px solid rgba(3, 13, 43, 0.35)",
                                    position: "absolute",
                                    zIndex: 99,
                                    width: "96%",
                                    borderRadius: "4px"
                                  }}>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#f0f0f0",
                                        cursor: "pointer",
                                        padding: "1rem",
                                        fontSize: "12px"
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                        padding: "1rem",
                                        fontSize: "12px"
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style
                                        })}>
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutoComplete>
                          <Form.Control.Feedback type="invalid">
                            Please Enter Organisation Address
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Button variant="link" type="submit" style={{ display: "none" }} id="submit_btn">
                        Save
                      </Button>
                    </>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </>
    );
  }
}
