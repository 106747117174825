import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import participants from "../../resources/images/participants.png";
import hotelrooms from "../../resources/images/hotelrooms.png";
import payment from "../../resources/images/Payment.png";
import ticket from "../../resources/images/ticket.png";
// import workshops from "../../resources/images/workshops.png";

export default class BookingNavigation extends Component {
  render() {
    return (
      <div>
        <Row className="mt-5">
          <Col className="">
            {/* <div className="wizard">
              <div className="wizard-inner">
                <div className="connecting-line" />
                <ul className="nav nav-tabs justify-content-center" role="tablist">
                  <li role="presentation" className="nav-item">
                    <a href="/" className="nav-link disabled">
                      <span className="round-tab">
                        <img src={participants} className="custom-icon" alt="" />
                      </span>
                    </a>
                    <small className="f1">Select Participants</small>
                  </li>
                  <li role="presentation" className="nav-item">
                    <a href="/" className="nav-link disabled">
                      <span className="round-tab">
                        <img src={hotelrooms} className="custom-icon" alt="" />
                      </span>
                    </a>
                    <small className="f1">Select Hotel Rooms</small>
                  </li>
                  <li role="presentation" className="nav-item">
                    <a href="/" className="nav-link disabled">
                      <span className="round-tab">
                        <img src={payment} className="custom-icon" alt="" />
                      </span>
                    </a>
                    <small className="f1">Do payment</small>
                  </li>
                  <li role="presentation" className="nav-item">
                    <a href="/" className="nav-link disabled">
                      <span className="round-tab">
                        <img src={ticket} className="custom-icon" alt="" />
                      </span>
                    </a>
                    <small className="f1">Get Ticket</small>
                  </li>
                 
                </ul>
              </div>
            </div> */}
          </Col>
        </Row>
      </div>
    );
  }
}
