import React from "react";
// import GoogleLogin from "react-google-login";
import { Form, Col, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "../../server/axios";
import Message from "./../common/Message";
import { Link } from "react-router-dom";

export default class Login extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      validated: false,
      full_name: "",
      email_id: "",
      password: "",
      signUpClicked: false,
      responseType: "",
      responseMessage: "",
      recaptchaValue: "",
      expired: "false",
    };

    this._reCaptchaRef = React.createRef();
  }
  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChange = (value) => {
    this.setState({ recaptchaValue: value, responseType: "" });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };
  onSignupClick(event) {
    // if (!this.state.recaptchaValue) {
    //   this.setState({
    //     responseType: "error",
    //     responseMessage: "Google recaptcha does not verified.",
    //     signUpClicked: false
    //   });
    //   event.preventDefault();
    // } else
    if (this.state.signUpClicked === false) {
      event.preventDefault();
      this.setState({ signUpClicked: true });
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        this.setState({ signUpClicked: false });
        event.stopPropagation();
      } else {
        axios({
          method: "POST",
          url: "/signup",
          data: {
            first_name: this.state.full_name,
            last_name: this.state.full_name,
            email_id: this.state.email_id,
            password: this.state.password,
          },
        })
          .then((response) => {
            this.setState({
              signUpClicked: false,
              responseType: "success",
              responseMessage:
                "Sign up is done, Just check your email and confirm the link we sent.",
              full_name: "",
              password: "",
              email_id: "",
              validated: false,
            });
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.log(error.response);
            this.setState({ signUpClicked: false });

            if (error.response) {
              if (error.response.data) {
                if (error.response.data.code === 409) {
                  this.setState({
                    responseType: "error",
                    responseMessage:
                      "Email is already registered, Go to login tab",
                  });
                } else if (error.response.data.code === 422) {
                  this.setState({
                    responseType: "error",
                    responseMessage:
                      "required field is missing, Do check if you are not using secure network.",
                  });
                }
              }
            }
          });
      }

      this.setState({ validated: true });
    }
  }

  render() {
    // const responseGoogle = response => {
    //   console.log(response);
    // };
    const { validated } = this.state;
    return (
      <>
        <h3 className="common-text_center f6 b  pt-3 mt-5">
          Create new account
        </h3>
        <br />

        <br />
        {this.state.responseType && (
          <Message type={this.state.responseType}>
            {this.state.responseMessage}
          </Message>
        )}
        <br />
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.onSignupClick(e)}
          className="pb-5 mt-3"
        >
          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustomName"
              className="form-group"
            >
              <Form.Control
                required
                type="text"
                className="form-control"
                name="full_name"
                pattern="^[^\s]{2,}.+$"
                value={this.state.full_name}
                onChange={this.onInputChange}
              />
              <Form.Label className="form-control-placeholder">
                Full Name
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please Enter Valid Full Name
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustomEmail"
              className="form-group"
            >
              <Form.Control
                required
                type="email"
                className="form-control"
                name="email_id"
                value={this.state.email_id}
                onChange={this.onInputChange}
              />
              <Form.Label className="form-control-placeholder">
                Email Address
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please Enter Valid Email Address
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustomPasword"
              className="form-group"
            >
              <Form.Control
                required
                type="password"
                className="form-control"
                name="password"
                pattern="^((?!.*[\s])(?=.*[a-z A-Z])(?=.*\d).{8,})"
                value={this.state.password}
                onChange={this.onInputChange}
              />
              <Form.Label className="form-control-placeholder">
                Password
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please Enter Valid Password
              </Form.Control.Feedback>
              <small>Minimum of 8 charecters, one letter and one number</small>
            </Form.Group>
          </Form.Row>
          <br />

          {/* <ReCAPTCHA sitekey="6LfgM_QhAAAAAOfS_l0WVG9cq1fjwfleasi66ni9" onChange={this.onChange} /> */}
          <br />
          <Button
            className="auth-primary mt-2 f3 b"
            size="lg"
            type="submit"
            block
          >
            CREATE AN ACCOUNT
          </Button>

          <small className="f1 fd1">
            By creating an account you agree to the &nbsp;
            <a target="_blank" href="https://2023.ux-india.org/privacy-policy/">
              Customer Privacy Policy
            </a>
            &nbsp;and&nbsp;
            <a target="_blank" href="https://2023.ux-india.org/terms/">
              Fair User Policy
            </a>
          </small>
        </Form>
        {/* below commented code will be required in future  so dont delete this code
        
        */}
        {/* <Row
          sm={12}
          md={10}
          lg={8}
          xl={10}
          className="justify-content-md-center"
          style={{ marginTop: 7 + "%", position: "absolute", width: 100 + "%" }}>
          <Col>
            <Row className="justify-content-md-center">
              <Col>
                <div className="common-text_center">
                  <div className="line" />
                  <div>
                    <span className="line-midWord">or</span>
                  </div>
                </div>
                <br />
                <div className="auth_signIn_google">
                  {" "}
                  <GoogleLogin
                    clientId="722957183643-n72btqd2e09nv3053b5p56nvcn8p2ek2.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                    buttonText="LOGIN WITH GOOGLE"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </>
    );
  }
}
