import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap";

export default class ViewBooking extends Component {
    render() {
        const {details}=this.props;
        let participants=details.guests_info.map((el,i)=>{
          return (<p key={i} className="b">{el}</p>)
        })
        let room='Single';
        if(details.room_type === 2){
          room='Double';
        }
        return (
          <Container>
            <Row className="f3">
              <Col>
                {participants}
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mb-0 ticket_caption">Check-in</p>
              </Col>
              <Col>
                <p className="mb-0 ticket_caption">Check-out</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mb-0 b">{details.check_in}</p>
              </Col>
              <Col>
                <p className="mb-0 b">{details.check_out}</p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p className="mb-0">{details.total_nights} Nights</p>
              </Col>
              <Col>
                <p className="mb-0">{room} Occupancy</p>
              </Col>
            </Row>
          </Container>
        )
    }
}
