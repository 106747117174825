import React from "react";
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
export default class EventTab extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "participants"
    };
  }
  componentDidMount = () => {
    let keys = ["participants", "hotel", "payment"];
    keys.forEach((each, index) => {
      if (this.props.progress === index) {
        this.setState({ key: each });
      }
    });
  };

  render() {
    return (
      <Card className="py-4 px-2">
        <Tabs activeKey={this.state.key} onSelect={key => this.setState({ key })} className="project-tab">
          <Tab
            eventKey="participants"
            title="Participants"
            className="custom-participant-tab f3 "
            disabled={this.props.progress === 0 ? false : true}>
            <Row className="justify-content-md-center">
              <Col xs={12} sm={12} md={10} lg={10}>
                {this.props.children}
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="hotel" title="Hotel" disabled={this.props.progress === 1 ? false : true}>
            <Row className="justify-content-md-center">
              <Col xs={12} sm={12} md={10} lg={10}>
                {this.props.children}
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="payment" title="Payment" disabled={this.props.progress === 2 ? false : true}>
          <Row className="justify-content-md-center">
              <Col xs={12} sm={12} md={10} lg={10}>
                {this.props.children}
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="ticket" title="Ticket" disabled />
          {/* <Tab eventKey="workshops" title="Workshops" disabled /> */}
        </Tabs>
      </Card>
    );
  }
}
