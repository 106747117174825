import React from "react";
// import Logo from "../../resources/images/umo-logo.png";
import Logo from "../../resources/images/umo-logo-new.png";
import { Navbar } from "react-bootstrap";

const AuthHeader = (props) => {
  return (
    <Navbar
      bg="transparent"
      className="custom-nav"
      expand="lg"
      style={{ height: 60 + "px" }}
    >
      <Navbar.Brand href="https://2023.ux-india.org">
        <img src={Logo} alt="Logo" className="header-logo" />
      </Navbar.Brand>
    </Navbar>
  );
};
export default AuthHeader;
