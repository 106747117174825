import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ticket_cover from "../../resources/images/ticket_cover.png";
import { formatDate, formatDate4 } from "./../common/Dates";
import { event } from "jquery";
export default class MyEvents extends Component {
  render() {
    return (
      <>
        <div className="dashboard_container">
          <Row className=" mt-5">
            <Col lg={12} md={12} xl={12}>
              <p className="f6 eb pl-1">My Events</p>
            </Col>

            {this.props.hasEvent === false ? (
              <div className="noevent">You haven't subscribed to any event yet.</div>
            ) : (
              <Row className="mt-4 mr-0 ml-3 pl-1" style={{ width: "100%" }}>
                {this.props.data.map((each, index) => {
                  const website_link = each.event_custom_url;
                  const ticket_link = "/dashboard/getticketDashboard/" + each.booking_unique_code + "/t";
                  const hotel_link = "/dashboard/bookhotel/" + each.booking_unique_code;
                  let formattedDate = formatDate4(each.start_datetime, each.end_datetime);
                  return (
                    <Col sm={8} md={6} lg={4} xl={4} className="px-0 mr-3 mb-3" key={index}>
                      <Card>
                        <Card.Header className="ticket_cover">
                          <img src={each.event_image} style={{ width: "100%" }} alt="ticket" />
                        </Card.Header>
                        <Card.Body className="card_body">
                          <p className="mb-0">{each.event_name}</p>
                          <p className="fd1 f2 mb-1">{each.location}</p>
                        </Card.Body>
                        <Card.Footer className="card_footer">
                          <Row>
                            <Col>
                              <a target="_blank" className="tab_links" href={website_link}>
                                Website
                              </a>
                            </Col>
                            {/* <Col>
                                <Link className="tab_links" to={hotel_link}>
                                  Hotel
                              </Link>
                              </Col> */}
                            <Col style={{ textAlign: "right" }}>
                              <Link traget="_blank" className="tab_links" to={ticket_link}>
                                Ticket
                              </Link>
                            </Col>
                          </Row>
                        </Card.Footer>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Row>
        </div>
      </>
    );
  }
}
