import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const HotelPopup = () => (
  <>
    <div className="f1 b ins" style={{ textAlign: "end", margin: 0 }}>
      <FontAwesomeIcon icon={faInfoCircle} size="lg" color="#007bff" /> &nbsp;{" "}
      <span className="fd1">
        important info about Check-in & Check-out&nbsp;
      </span>
      <Popup
        className="popup_main"
        trigger={<button className="popup_button">details </button>}
        position="bottom right"
        offsetX={0}
      >
        {(close) => (
          <>
            <a className="close" onClick={close}>
              &times;
            </a>
            <div className="popup_content">
              <p className="mt-2">
                <b>Deluxe Room:</b>
              </p>
              <ul>
                <li>Breakfast at the designated venue</li>
                <li>Tea / Coffee maker in room</li>
                <li>Two bottles of packed drinking water</li>
                <li>
                  Two-line speaker IP phones with voice mail and modem jack
                </li>
                <li>Computer and Fax hookup facilities</li>
                <li>In-room electronic safe</li>
                <li>Wi-Fi (unlimited on shared bandwidth)</li>
                <li>Iron and Iron board</li>
              </ul>
              <p className="mt-3">
                <b>Check In/Check Out</b>
              </p>
              <ul>
                <li>Our check-in/check-out time is 1400hrs onwards</li>
                <li>
                  Reservations with check-in prior to O800hrs are held from the
                  previous evening and are billed from the previous evening
                  itself.
                </li>
                <li>
                  For check in between 0800hrs to 1100hrs, half day rate (50%)
                  would be charged for the early check in.
                </li>
                <li>
                  Between 1100hrs to 1400hrs, the room may be provided at no
                  charge (sublect to availability)
                </li>
              </ul>
            </div>
          </>
        )}
      </Popup>
    </div>
  </>
);
