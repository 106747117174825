import React, { Component } from 'react';
import AddParticipant from "../selectParticipants/AddParticipant";
import EventTab from "../common/EventTab";
import { Button } from "react-bootstrap";
import UpdateParticipant from './UpdateParticipant';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default class EditParticipantDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            removeIndex: [],
            participants: this.props.participants,
            newParticipant: false,
            participant_panels: [],
        }
    }
    componentDidMount = () => {
        let addParticipant = [];
        this.state.participants.forEach((el,i) => {
            addParticipant.push(<UpdateParticipant key={i} id={i} removePanel={this.removePanel.bind(this)} addParticipant={this.addParticipant.bind(this)} data={el} />);
        });
        this.setState({
            participant_panels: addParticipant,
        })
    }
    removePanel = (id) => {
        let participant_panels = this.state.participant_panels;
        if (participant_panels.length > 1) {
            this.removeParticipant(id);
            let participants = participant_panels.filter((value, index) => {
                if (id !== value.props.id) {
                    return value;
                }
            });
            this.setState({
                participant_panels: participants
            }, () => {
                this.props.removeParticipants();
            })
        }
    }
    addParticipant = (data) => {
        this.removeParticipant(data.id);
        this.setState(prevState => ({
            participants: [...prevState.participants, data]
        }), () => {
            this.props.participantDetails(this.state.participants);
        });
    }
    removeParticipant = (id) => {
        if (id !== undefined) {
            let participants = this.state.participants;
            this.setState({
                participants: participants.filter((value, index) => {
                    if (id !== value.id) {
                        return value;
                    }
                })
            }, () => {
                this.props.participantDetails(this.state.participants);
            })
        }
    }
    addNewParticipant = (event) => {
        let participant_panels = this.state.participant_panels, id;
        if (participant_panels.length > 0) {
            let len = participant_panels.length - 1;
            id = (participant_panels[len].props.id + 1);
        } else {
            id = 0;
        }
        participant_panels.push(<AddParticipant key={id} id={id} removePanel={this.removePanel.bind(this)} addParticipant={this.addParticipant.bind(this)} />);
        this.setState({
            participant_panels: participant_panels
        }, () => {
            this.props.addParticipants();
        });
    }
    validateParticipantDetails = () => {
        var buttons = document.getElementsByClassName('add_participant_submit');
        for (var i = 0; i < buttons.length; i++)
            buttons[i].click();
    }
    render() {
        const { participant_panels } = this.state;
        let disabled = false;
        if (participant_panels.length >= 10) {
            disabled = true
        }
        return (
            <>
                <p className="participant-details f6 eb">Participant - Details</p>
                <EventTab progress={0}>
                    {participant_panels.length > 0 ? (participant_panels) : (<p className="text-center my-4">Select atleast one participant</p>)}
                    <div className="float-right mt-2">
                        <Button
                            variant="link"
                            style={{ textDecoration: "none", color: "#3E6Ce2" }}
                            onClick={this.addNewParticipant.bind(this)}
                            disabled={disabled}
                        >
                        <FontAwesomeIcon icon={faPlus}/> Add Participant
                        </Button>
                    </div>
                </EventTab>
            </>
        );
    }
}
