import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import UmoLogo from "../../resources/images/umo-logo-new.png";
// import UmoLogo from "../../resources/images/umo-ticket.png";
// import UxLogo from "../../resources/images/uxindia-logo.png";
import UxLogo from "../../resources/images/uxindia-logo-new.png";
import { Link } from "react-router-dom";
import { formatDate,formatDate4 } from "./../common/Dates";
const TicketDetails = props => {
  const p = props.participant_detail; //array []
  const e = props.event_details; //object
  const package_name = e.package_name;

  const formatedDateforEvent = formatDate4(e.event_start_datetime, e.event_end_datetime);
  const fromattedDateforTicket = formatDate(e.event_package_start_date, e.event_package_end_date);
  return (
    <Card className="mt-1 pt-4 shadow">
      <Row className="justify-content-md-center mx-0">
        <Col xs={12} sm={12} md={10} lg={10} xl={8}>
          <div style={{ textAlign: "center" }}>
            {/*<img src={UxLogo} alt="ux-india" width="72px" height="53px"/>*/}
            <h4 className="f22 b mt-2 mb-0 pb-0"> {e.event_name}</h4>
            <hr />
          </div>
          <Row className="mb-3 pb-3 pt-5 mx-0 ticket_container">
            <Col lg={6} md={6} xl={8} className="mb-2 pl-0">
              <img src={e.ticket_alphabet} alt="ticket_alphabet" style={{ width: 100 + "px" ,  height: 100 + "px" }} />
              <br />
              <span className="f2  fd1 eb" style={{ letterSpacing: "1.2px" }}>
                TICKET NO.
              </span>
              <br />
              <span className="f2 eb" style={{ letterSpacing: "1.2px" }}>
                {props.ticket_number}
              </span>
              <br />
              {/* <span className="code_name uppercase">{package_name}</span> */}
            </Col>
            <Col lg={6} md={6} xl={4} className="pl-0 f2 section1">
              <span>
                Attending:<b> {e.ticket_description}</b>
              </span>
              <br />
              <span>
                Participants: <b>{p.length}</b>
              </span>
              <br />

              <div className="mt-2 mb-0">
                Name (s): <br />
                <div className="f3 b">
                  {p.map((each, index) => {
                    return (
                      <p className="mb-0 pb-0" key={index}>
                        {each.first_name}
                      </p>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="pb-3">
            <Col lg={6} md={6} xl={8} className="ticket_powered">
              <p className="f2 pt-1 pb-0 mb-0"> {e.event_name}</p>
              <div className="f1 pt-2">
                <p className="pb-0 mb-0" style={{ width: 250 + "px" }}>
                  {e.event_location}
                </p>
                {e.event_website}<br></br>
                <b>{e.ticket_description}</b>
              </div>
            </Col>
            <Col lg={6} md={6} xl={4} className="ticket_powered pl-0  org_align">
              <img src={UmoLogo} alt="umoDesign" className="mb-1 ticket_umo" style={{ width: 28 + "%" }} />
              <p className="f1 org_align" style={{ lineHeight: 17 + "px" }}>
                Organized by <br /> <b>{e.organized_by}</b>
                <br />
                {e.contact_email}
                <br />
                <b>
                  Help Line:
                  <a style={{ color: "black" }} href={"tel:" + e.contact_number}>
                    {e.contact_number}
                  </a>
                </b>
              </p>
            </Col>
            {/*<p className="ticket_note f1">
              Please bring in this ticket in print or digital to check in at Conference by 8:00 AM
                </p>*/}
            <hr className="ticket_note_hr" />
            {!props.fromDashboard && (
              <span className="ticket_login_note f2 fd1">
                The ticket is available when you &nbsp;<Link to="/auth">login</Link> &nbsp;to your dashboard .
              </span>
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default TicketDetails;
