import React, { Component } from "react";
import Panel from "../common/Panel";
import { Link } from "react-router-dom";
export default class AboutEvent extends Component {
  render() {
    return (
      <div className="mt-5">
        <Panel title="ABOUT THE EVENT">
          <div className="ticket-padding f2">
            <p className="f2 b no-padding">
              UMO Design Grad 2024: India's Biggest Int'l UX Conference
            </p>
            <p className="f2">
              3-day event tailored for UX designers, researchers, leaders, and
              students. Join us from 28-30 Sep at The Leela, Bharatiya City,
              Bengaluru for inspiration,education and networking opportunities.
            </p>

            <h2 className="f2 b no-padding">
              Get Insipiration From Industry Leaders:
            </h2>
            <p className="f2">
              We bring engaging industry leaders from around the world to
              present a combination of inspirational talks and lively hands-on
              workshops.<br></br>
              <br></br>UMO Design Grad 2024 brings you a rich mix of speaker talks,
              workshops, and panels sessions involving inspirational speakers
              and thought leaders involved in leading transformation by design.
              This year's conference is designed to bring more awareness about
              the role of AI and how it can transform UX.
            </p>

            <h2 className="f2 b no-padding">Cancellation Policy</h2>
            <p className="f2">
              There are no refunds on cancellation.<br></br>
              <strong>Transfers: </strong>Registrations are transferable, we
              require notice of such in writing, a minimum of 10 days prior to
              the event.
            </p>
            <h2 className="f2 b no-padding">Privacy Policy</h2>
            <p className="f2">
              By accepting our Terms of Service, you are also agreeing to our{" "}
              <a
                target="_blank"
                href="https://2023.ux-india.org/privacy-policy/"
              >
                Privacy Policy.
              </a>
            </p>
            <h2 className="f2 b no-padding">Code of Conduct</h2>
            <p className="f2">
              We're dedicated to providing a safe, harassment-free conference
              experience for all participants—regardless of gender, sexual
              orientation, disability, physical appearance, race, age or
              religion. Learn more about our{" "}
              <a
                target="_blank"
                href="https://2023.ux-india.org/code-of-conduct/"
              >
                Code of Conduct.
              </a>
            </p>
          </div>
        </Panel>
      </div>
    );
  }
}
