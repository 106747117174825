const host = "https://goeventum.com"; //prod
// const host = "http://52.55.18.143"; //dev
//const host = "https://Uxindia.zensark.com"; //dev
export default {
  getEventDetails: host + "/api/events/info/",
  getTicketDetails: host + "/api/package/<package_id>/ticket/<ticket_id>",
  addParticipants: host + "/api/events/gettingstarted",
  getBookingDetails: host + "/api/get/booking-details/<booking_unique_code>",
  getHotelDetails: host + "/api/events/<event_id>/hotels-info",
  signUp: host + "/api/signup",
  bookHotel: host + "/api/bookhotel/<booking_id>",
};
