import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";

export default class Footer extends Component {
  render() {
    return (
      <div className="mt-5 custom-footer">
        <Container>
          {this.props.error_msg &&
            <Row className="pt-4">
              <Col sm={12} md={12} lg={12} xl={12} className="text-center">
                <small className="text-danger">*Please Save all the boxes above to proceed</small>
              </Col>
            </Row>
          }
          <Row className="pt-4">
            <Col sm={12} md={12} lg={9} xl={9}>
              <div style={{ display: "flex", float: "right" }}>
                {this.props.children}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
