import React, { Component } from "react";
// import { Row, Col, Card } from "react-bootstrap";
import Panel from "../common/Panel";
// import Workshop from "../common/Workshop";

export default class EventPrograms extends Component {
    render() {
        //dividing a day programs
        // let perChunk = 2; // items per chunk
        // let inputArray = this.props.workshops[0].schedules;
        // let final_array = inputArray.reduce((all, one, i) => {
        //   const ch = Math.floor(i / perChunk);
        //   all[ch] = [].concat(all[ch] || [], one);
        //   return all;
        // }, []);

        // const programs = final_array.map((value, index) => {
        //   const program_title = value.map((el, index) => {
        //     return (
        //       <Col key={index}>
        //         <p className="f3 b mb-0">{el.schedule_title}</p>
        //       </Col>
        //     );
        //   });
        //   const program = value.map((el, index) => {
        //     if (el.workshop_details !== null) {
        //       const tracks = el.workshop_details.map((track, index) => {
        //         return <Workshop key={index} data={track} />;
        //       });
        //       return <Col key={index}>{tracks}</Col>;
        //     } else {
        //       return (
        //         <Col key={index}>
        //           <p className="f2">{el.for_all_data}</p>
        //         </Col>
        //       );
        //     }
        //   });
        //   return (
        //     <Card className="bg-card-program mt-4" key={index}>
        //       <Card.Body>
        //         <Row>{program_title}</Row>
        //         <hr />
        //         <Row>{program}</Row>
        //       </Card.Body>
        //     </Card>
        //   );
        // });
        return (
            <div className="mt-5">
                <Panel title="PROGRAM">
                    <p className="f3 b mt-4 mb-0">Register to choose the workshops</p>
                    {/* {programs} */}
                    <div className="hard_programs mt-3">
                        <div className="hard_programs_header">
                            <div>
                                <p className="f3 mb-0">
                                    <span className="b">09 Sept’2019 - Workshops</span>&nbsp;(10 am - 01pm)
                </p>
                            </div>
                            <div>
                                <p className="f3 mb-0">
                                    <span className="b">09 Sept’2019 - Workshops</span>&nbsp;(02pm - 05pm)
                </p>
                            </div>
                        </div>
                        <div className="hard_description f2">
                            <div className="pl-5">
                                <p className="b mb-0">Immersive hands on workshops.</p>
                                <p>Once you registered to the conference, you will receive workshop selection link.</p>
                            </div>
                        </div>
                    </div>

                    <div className="hard_programs mt-3">
                        <div className="hard_programs_header_2">
                            <div>
                                <p className="f3 mb-0">
                                    <span className="b">10 Sept’2019 - Workshops </span>&nbsp;(08 am - 01pm)
                </p>
                            </div>
                            <div>
                                <p className="f3 mb-0">
                                    <span className="b">10 Sept’2019 - Talks & Design Expo </span>&nbsp;(02pm - 07pm)
                </p>
                            </div>
                        </div>
                        <div className="hard_description_2 f2">
                            <div style={{ borderTop: "1px solid #e0e0e0", paddingTop: "21px" }}>
                                <div>
                                    <p className="b mb-0">Immersive hands on workshops.</p>
                                    <p>Once you registered to the conference, you will receive workshop selection link.</p>
                                </div>
                            </div>
                            <div style={{ borderTop: "1px solid #e0e0e0", paddingTop: "21px" }}>
                                <div>
                                    <p className="b mb-0">Talks & Design XPO</p>
                                    <p>
                                        UX interaction day is jam packed with UX designers, UX leaders, user researchers, technologists,
                                        startup founders and their stories. XPO hosts stalls of industry leading compnies and best entries
                                        of Design X Social. It’s a good place for networking and recruitment.
                  </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="hard_programs mt-3">
                        <div className="hard_programs_header">
                            <div>
                                <p className="f3 mb-0">
                                    <span className="b">11 Sept’2019 - Ball Room Conference)</span>&nbsp; (08 am - 05pm)
                </p>
                            </div>
                            <div />
                        </div>
                        <div className="hard_description f2">
                            <div className="pl-5">
                                <p className="b mb-0">Keynotes and Panels</p>
                                <p>
                                    Some of the best practitioners in user experience are joining us to share the latest trends and ideas.
                                    And Design X Awards.
                </p>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
        );
    }
}
