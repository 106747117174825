import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Footer from "./../common/Footer";
import BookingHeader from "./../common/BookingHeader";
import Message from "./../common/Message";
import TicketDetails from "./TicketDetail";
import HotelDetail from "./HotelDetail";
import axios from "../../server/axios";
import { Loader } from "./../common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";

export default class GetTicket extends React.Component {
  state = {
    data: {},
    participant_detail: [], //attendees_details
    event_details: {}, //event_details
    redirect_to_select_participant: false,
    hotel_booking_details: [], //hotel_booking_details
    pageLoaded: false,
    fromDashboard: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.match.params.fromdashboard) {
      this.setState({ fromDashboard: true });
    }
    axios({
      method: "GET",
      url: "/get/generated-ticket/" + this.props.match.params.booking_code
    })
      .then(response => {
        const data = response.data.booking_details;
        this.setState({
          data: data,
          participant_detail: data.attendees_details,
          event_details: data.event_details,
          hotel_booking_details: data.hotel_booking_details,
          pageLoaded: true
        });
        window.scrollTo(0, 0);
      })
      .catch(error => {
        this.setState({
          redirect_to_select_participant: true
        });
        console.log(error);
      });
  }

  render() {
    const { data } = this.state;
    const { redirect_to_select_participant } = this.state;
    return (
      <>
        {redirect_to_select_participant && (
          <Redirect to={"/edit-participants/" + this.props.match.params.booking_code} />
        )}
        <BookingHeader progress={3} fromDashboard={this.state.fromDashboard} />
        <Container className="main-content">
          <Row className="mt-5 mx-0">
            <p className="participant-details  f6 eb">Ticket - details </p>
          </Row>
          {!this.state.pageLoaded ? (
            <Loader />
          ) : (
            <>
              <Row className="justify-content-md-center">
                <Col xs={12} sm={12} md={10} lg={8} xl={8}>
                  {!this.state.fromDashboard && (
                    <Message type="success">
                      You have successfully registered to the Conference. Confirmation has been sent to your email.
                      Please check your
                      <b> spam</b> folder if you dont find it in Inbox. <br />
                    </Message>
                  )}
                </Col>
              </Row>
              <Row className="justify-content-md-center" id="print_this">
                <Col xs={12} sm={12} md={12} lg={10} xl={12}>
                  <Row className="mt-4">
                    <Col xs={4} sm={4} md={4}>
                      <p className="f3 b">Your Ticket</p>
                    </Col>
                    <Col className="ticket_links f1 pl-0 ml-0">
                      {/* <span>Share</span>&emsp; */}
                      <span className="print_option" onClick={e => window.print()}>
                        Print
                      </span>
                      &emsp;
                      {/* <span>Add to calendar</span>&emsp;
                      <span>
                        <FontAwesomeIcon style={{ color: "#ce0046" }} icon={faFilePdf} size="lg" />
                        &nbsp;Download as PDF
                      </span> */}
                      &nbsp;
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <TicketDetails
                      participant_detail={this.state.participant_detail}
                      event_details={this.state.event_details}
                      ticket_number={data.ticket_number}
                      fromDashboard={this.state.fromDashboard}
                    />
                    {this.state.hotel_booking_details.length > 0 && (
                      <HotelDetail hotel_booking_details={this.state.hotel_booking_details} />
                    )}
                  </Row>
                  <Row />
                </Col>
              </Row>
            </>
          )}
        </Container>

        <Footer />
      </>
    );
  }
}
