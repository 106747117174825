import React, { Component } from 'react';
import { Redirect,} from "react-router-dom";
import { Loader } from './common/Loader';


export default class IndexRoute extends Component {
    render() {
        return (
            <>  
                <Loader />
                <Redirect to={"/event/1/package/1"} />
            </>
        )
    }
}
