import React, { Component } from "react";
import { Card } from "react-bootstrap";

export default class HotelSummary extends Component {
  render() {
    const { hotels_info, booking_details, ticket_amount, hotel_name, description } = this.props;
    let rooms = 0,
      gst = 0,
      amount = 0,
      gateway_fee = 0,
      total = 0,
      final_amount = 0;
    if (booking_details.length > 0) {
      let single_room_price,
        double_room_price,
        single_rooms_price = 0,
        double_rooms_price = 0;
      hotels_info.forEach(element => {
        if (element.room_type === 1) {
          single_room_price = element.price_per_night;
        } else if (element.room_type === 2) {
          double_room_price = element.price_per_night;
        }
      });
      booking_details.forEach(element => {
        if (element.room_type === 1) {
          single_rooms_price += (element.total_nights * single_room_price);
        } else if (element.room_type === 2) {
          double_rooms_price += (element.total_nights * double_room_price);
        }
        rooms++;
      });
      amount = (single_rooms_price + double_rooms_price).toFixed(2);
      gst = (parseFloat(amount) * 0.18).toFixed(2);
      gateway_fee = ((parseFloat(amount) + parseFloat(gst)) * 0.04).toFixed(2);
      total = (parseFloat(amount) + parseFloat(gst) + parseFloat(gateway_fee)).toFixed(2);
    }
    final_amount = (parseFloat(total) + parseFloat(ticket_amount)).toFixed(2);
    return (
      <>
        <Card className="py-2 mt-3 " style={{ backgroundColor: "#E4E9F0" }}>
          <p className="f4 b  mb-0 px-3">Hotel - {hotel_name}</p>
          {/* <span className="f1 fd2 ticket-caption px-3">{this.props.location}</span> */}
          <hr />
          <div className="px-4">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>Rooms</td>
                  <td className="float-right">
                    <h4 className="f5 b">{rooms}</h4>
                  </td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td className="float-right">&#8377; {Number(amount).toLocaleString('en-IN')}</td>
                </tr>
                <tr>
                  <td>GST (18%)</td>
                  <td className="float-right">&#8377; {Number(gst).toLocaleString('en-IN')}</td>
                </tr>
                <tr>
                  <td>Gateway Fee</td>
                  <td className="float-right">&#8377; {Number(gateway_fee).toLocaleString('en-IN')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div className="px-4">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>Amount</td>
                  <td className="float-right">
                    <h4 className="f5 b">&#8377; {Number(total).toLocaleString('en-IN')}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>

        <Card className="py-2 px-3 mt-3" style={{ backgroundColor: "#E4E9F0" }}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td className="f3 b">Total</td>
                <td className="float-right">
                  <h4 className="f5 b">&#8377; {Number(final_amount).toLocaleString('en-IN')}</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </>
    );
  }
}
