import React, { Component } from "react";
import axios from "../../../server/axios";

import { HashRouter as Router, Route, Switch, Link } from "react-router-dom";
import NotFoundError from "../../common/NotFoundError";
import AdminEvents from "./AdminEvents";
import AdminSetting from "./AdminSetting";
import { Sidebar } from "./Sidebar";
import TopNav from "./TopNav";
import { Loader } from "../../common/Loader";
import { NotificationManager } from "react-notifications";
import DashboardSummary from "./DashboardSummary";
import ParticipantDetailDashboard from './participantDetailDashboard';

export default class AdminDashboardLayout extends Component {
  state = {
    verified: false,
    data: {},
    userData: {},
    hasEvent: false,
    eventData: [],
    networkError: false,
    user_type: "empty"
  };
  componentDidMount() {
    axios({
      method: "GET",
      url: "/dashboard",
      headers: {
        "Access-Token": localStorage.accessToken
      }
    })
      .then(response => {
        // if (response.data.message === "no_events_found") {
        //   this.setState({ hasEvent: false, verified: true });
        // }

        if (response.data.message === "success" && localStorage.getItem("user_type") === "2") {
          this.setState({
            data: response.data,
            hasEvent: true,
            userData: { name: response.data.name, email: response.data.email },
            eventData: response.data.events,
            verified: true,
            user_type: localStorage.getItem("user_type")
          });
        }
        else if (localStorage.getItem("user_type") !== 2) {
          localStorage.clear();
          this.setState({ user_type: "" })
        }
      })
      .catch(error => {
        console.log(error.response);
        if (error.response.data.message === "invalid_token") {
          localStorage.removeItem("accessToken");
          NotificationManager.error("session is expired", "Login again", 5000);
          this.props.history.push("/auth");
        }
        if (error.response.status === 500) {
          this.setState({ networkError: true });
        }
      });
  }

  render() {
    return (
      <>
        {this.state.networkError && (
          <div style={{ height: "100vh", background: "lightgray" }}>
            <center style={{ paddingTop: "20%", textTransform: "capitalize" }}>
              <p className="mb-1 b">oops! something went wrong. </p>

              <p className="f2">please try later</p>
            </center>
          </div>
        )}

        {this.state.user_type === "" && (
          <div style={{ height: "100vh", background: "lightgray" }}>
            <center style={{ paddingTop: "20%", textTransform: "capitalize" }}>
              <p className="mb-1 b">oops! you are not authorized. </p>

              <p className="f2">please go to <Link to="/auth">login</Link> page</p>
            </center>
          </div>
        )}

        {(this.state.verified === false && this.state.networkError === false) && (
          <Loader />
        )}
        {(this.state.verified === true && this.state.networkError === false) &&
          (
            <div id="#page-content-wrapper">
              <div className="d-flex" id="wrapper">
                <Sidebar pathname={this.props.history.location.pathname} userData={this.state.userData} />
                <div id="page-content-wrapper">
                  <TopNav />
                  <div>
                    <Switch>
                      <Route
                        exact
                        path="/organization/dashboard"
                        render={props => <AdminEvents {...props} data={this.state.eventData} />}
                      />
                      <Route exact path="/organization/dashboard/summary/:event_id" component={DashboardSummary} />
                      <Route exact path="/organization/dashboard/summary/participant/:booking_code" component={ParticipantDetailDashboard} />
                      <Route path="/organization/dashboard/AdminSetting" component={AdminSetting} />
                      <Route component={NotFoundError} />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}
