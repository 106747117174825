import React, { Component } from "react";
import { Card, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faAngleUp, faAngleDown, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default class Panel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: !this.props.collapsed
    };
  }
  removePanel = () => {
    this.props.removePanel(this.props.id);
  };
  editPanel = () => {
    this.props.editPanel(this.props.id);
  };
  render() {
    const { open } = this.state;
    const { removable, title, editable, hideCollapsable, children } = this.props;
    return (
      <Card>
        <Card.Header className="f3 b">
          <span dangerouslySetInnerHTML={{ __html: title }} />
          <span className="float-right">
            {removable && (
              <span className="pointer pr-3" onClick={this.removePanel.bind(this)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </span>
            )}
            {editable && (
              <span className="pointer pr-3" onClick={this.editPanel.bind(this)}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </span>
            )}
            {/* {hideCollapsable === true ? (
              ""
            ) : (
              <span
                className="pointer"
                onClick={() => this.setState({ open: !open })}
                aria-controls="collapsable-panel"
                aria-expanded={open}>
                {open ? (
                  <FontAwesomeIcon icon={faAngleUp} size="lg" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                )}
              </span>
            )} */}
          </span>
        </Card.Header>
        <Collapse in={open}>
          <Card.Body id="collapsable-panel">{children}</Card.Body>
        </Collapse>
      </Card>
    );
  }
}
