import React from "react";
import { Card, Row, Col } from "react-bootstrap";
const HotelDetail = props => {
  const h = props.hotel_booking_details; //array []
  return (
    <>
      <Col xs={12} sm={12} lg={12} xl={12} md={12} className="font-weight-bold mt-5">
        <h4 className="f3 b">Hotel booking</h4>
      </Col>
      <Col xs={12} sm={12} lg={12} xl={12} md={12}>
        <Card className="shadow mt-2">
          <Row className="px-3">
            {h.map((each, index) => {
              const guestsInfo = JSON.parse(each.guests_info);
              return (
                <Col xs={12} sm={12} md={6} lg={4} xl={4} key={index} className="my-3">
                  <Card className="px-4 py-2">
                    <span className="booking_no fd1 f3 b">Booking &nbsp; 0{index + 1}</span>
                    <br />
                    {guestsInfo.map((info, index) => {
                      return (
                        <p key={index} className="mb-0 f3 b">
                          {info}
                        </p>
                      );
                    })}
                    <table className="detail mt-1 f2 mb-3">
                      <tbody>
                        <tr className="fd1">
                          <td>Check-in</td>
                          <td>Check-out</td>
                        </tr>
                        <tr>
                          <td>
                            <b>{each.check_in}</b>
                          </td>
                          <td>
                            <b>{each.check_out}</b>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>{each.total_nights}</b> Nights
                          </td>
                          <td>
                            {each.room_type === 1 ? "Single" : each.room_type === 2 ? "Double" : "Tripple"}
                            &nbsp;occupancy
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </Col>
              );
            })}
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="my-3">
              <Card className="hotel_address px-4 py-2">
                <span className="booking_no fd1 f3 b">Hotel Address</span>
                <div className="pl-3 pt-3 f2" style={{ lineHeight: 22 + "px" }}>
                  <p className="f3 b mb-0">{h[0].hotel_name},</p>
                  <p>{h[0].address}</p>
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default HotelDetail;
