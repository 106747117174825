import React from "react";
import { Link } from "react-router-dom";
// import Umo from "../../../resources/images/umo-logo.png";
import Umo from "../../../resources/images/umo-logo-new.png";
import Ux from "../../../resources/images/uxindia-logo-new.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserCircle } from "@fortawesome/free-solid-svg-icons";
export const Sidebar = props => {
  return (
    <div className="bg-light border-right" id="sidebar-wrapper">
      <div className="sidebar_header">
        <img className="sidebar_header_logo" src={Umo} alt="umo" style={{ height: "35px" }} />
        <img
          className="sidebar_header_logo"
          src={Ux}
          alt="ux"
          style={{ height: "25px", paddingTop: "10px" }}
        />
        <center className="side_profile mt-2">
          <p className="pb-0 mb-0 f3" style={{ color: "white", textTransform: "capitalize" }}>
            {props.userData.name}
          </p>
          <p className="pt-0 mt-0 f2" style={{ color: "#999BA4" }}>
            {props.userData.email}
          </p>
          <p>
            <FontAwesomeIcon icon={faUserCircle} size="4x" color="white" />
          </p>
        </center>
      </div>
      <div className="list-group list-group-flush side_nav">
        <Link
          to="/organization/dashboard"
          className={
            props.pathname === "/organization/dashboard" || props.pathname === "/organization/dashboard/" || props.pathname.includes("/organization/dashboard/summary/")
              ? "side_nav_items side_nav_active"
              : "side_nav_items"
          }
          replace>
          Events
        </Link>
        {/* <Link
          to="/dashboard/UserSetting"
          className={props.pathname.includes("UserSetting") ? "side_nav_items side_nav_active" : "side_nav_items"}
          replace>
          Settings
        </Link> */}
      </div>
    </div>
  );
};
