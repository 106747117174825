import React, { Component } from "react";
import BookingHeader from "../common/BookingHeader";
import { Row, Form, Col, Container, Button, Card, Nav } from "react-bootstrap";
import EventTab from "../common/EventTab";
import axios from "../../server/axios";
import { Loader } from "./../common/Loader";
import Message from "./../common/Message";
import { Link } from "react-router-dom";
import AboutButton from "../common/AboutButton";
import rolling from "../../resources/images/rolling.gif";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

export default class Payment extends Component {
  state = {
    data: {},
    participant_detail: [], //attendees_details
    org_detail: {}, //billing_info
    package_detail: {}, //package_details
    tikcet_detail: {}, //ticket_details
    hotel_detail: [], //hotel_booking_details
    event_details: {}, //event_details
    pageLoaded: false,
    payment_response: {},
    paymentSuccessfull: false,
    gateway_error_message: "",
    is_payment_clicked: false,
    discount: "",
    validated: false,
    validationError: false,
    discountAmount: 0,
    errorMessage: "",
    isProcessing: false,
    needClear: false,
  };

  //test ticket id : 8WOIZIX5XTD
  componentDidMount() {
    window.scrollTo(0, 0);
    axios({
      method: "GET",
      url: "/get/booking-details/" + this.props.match.params.booking_code,
    })
      .then((response) => {
        const booking_details = response.data.booking_details;
        this.setState({
          data: booking_details,
          participant_detail: booking_details.attendees_details,
          org_detail: booking_details.billing_info,
          package_detail: booking_details.package_details,
          tikcet_detail: booking_details.ticket_details,
          hotel_detail: booking_details.hotel_booking_details,
          event_details: booking_details.event_details,
          pageLoaded: true,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  handleSubmit(e) {
    // console.log("hi");
    e.preventDefault();
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   console.log("hello");
    //   e.stopPropagation();
    // }

    // this.setState({ validated: true });
  }

  onDiscount = () => {
    if (this.state.discount) {
      this.setState({ discountAmount: 0, isProcessing: true });
      axios({
        method: "POST",
        url: `/validatecoupon/${this.props.match.params.booking_code}/${this.state.discount}`,
      })
        .then((response) => {
          this.setState({
            validated: true,
            validationError: false,
            discountAmount: response.data.total_discounted.toFixed(2),
            isProcessing: false,
            needClear: true,
          });

          NotificationManager.success(
            "Coupon applied successfully!",
            "Congrats",
            5000
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.setState({
            validate: false,
            validationError: true,
            isProcessing: false,
          });
          switch (error.response.data.message) {
            case "attendees_should_be_two_or_below":
              this.setState({
                errorMessage:
                  "This coupon is only applicable for upto 2 participants",
              });
              break;
            case "coupon_expired":
              this.setState({ errorMessage: "This coupon is expired." });
              break;
            case "coupon_usage_exhausted":
              this.setState({ errorMessage: "Coupon limit exceeded." });
              break;
            case "coupon_not_available":
              this.setState({ errorMessage: "This coupon is expired." });
              break;
            case "coupon_invalid":
              this.setState({ errorMessage: "This coupon is invalid." });
              break;
            default:
              break;
          }
        });
    } else {
      this.setState({ validationError: true });
    }
  };

  onPaymentClicked = () => {
    // event.preventDefault();

    this.setState({ is_payment_clicked: true });
    axios({
      method: "POST",
      url: "/createpayment/" + this.props.match.params.booking_code,
      data: {
        is_coupon_applied: this.state.validated,
        coupon_code: this.state.validated ? this.state.discount : "",
      },
    })
      .then((response) => {
        console.log("response");
        console.log(response);
        console.log(this.props.match.params.booking_code);
        this.setState({ payment_response: response.data });
        if (response.data.message === "free_payment_success") {
          this.props.history.push(
            "/getticket/" + this.props.match.params.booking_code
          );
        } else {
          this.proceedToGateway(response.data);
        }
      })
      .catch((error) => {
        this.setState({ is_payment_clicked: false });
      });
  };
  proceedToGateway = (data) => {
    const gatewayResponse = (response) => {
      console.log("response");
      console.log(response);
      console.log(this.props.match.params.booking_code);
      if (response.error) {
        console.log("error");
        console.log(response.error);
        this.setState({ gateway_error_message: response.error.description });
      } else if (response.razorpay_signature) {
        console.log("success");
        this.setState({ paymentSuccessfull: true, is_payment_clicked: false });
        setTimeout(() => {
          this.props.history.push(
            "/getticket/" + this.props.match.params.booking_code
          );
        }, 10000);
      }
    };
    const closeModal = () => {
      this.setState({ is_payment_clicked: false });
    };
    var options = {
      key: "rzp_live_s0MEPojnhfvB1c",
      // key: "rzp_test_yiuM5vJomlztCE", // Enter the Key ID generated from the Dashboard
      amount: data.amount, // INR 299.35
      name: "UMO Design Grad 2024",

      order_id: data.payment_id, //Order ID is generated as Orders API has been implemented. Refer the Checkout form table given below
      handler: function (response) {
        gatewayResponse(response);
      },

      prefill: {
        email: data.user_email,
        contact: data.user_contact,
      },
      notes: {
        address: data.booking_unique_code,
      },
      theme: {
        color: "#3e6ce2",
      },
      modal: {
        ondismiss: function () {
          closeModal();
        },
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  render() {
    const { pageLoaded, event_details, package_detail, validated } = this.state;
    let hotel_total = 0,
      ticket_total = 0,
      subtotal = 0,
      gst = 0,
      gatewayFee = 0,
      total = 0,
      discount = 0,
      final_amount = 0,
      ticket_price = 0,
      event_id = 1;
    ticket_total =
      this.state.tikcet_detail.price * this.state.participant_detail.length;
    this.state.hotel_detail.forEach((hotel, index) => {
      hotel_total = hotel_total + hotel.price_per_night * hotel.total_nights;
    });
    ticket_price = this.state.tikcet_detail.price;
    subtotal = (hotel_total + ticket_total - this.state.discountAmount).toFixed(
      2
    );
    gst = parseFloat(subtotal * 0.18).toFixed(2);
    gatewayFee = ((parseFloat(subtotal) + parseFloat(gst)) * 0.04).toFixed(2);
    total = (
      parseFloat(subtotal) +
      parseFloat(gst) +
      parseFloat(gatewayFee)
    ).toFixed(2);
    final_amount = (parseFloat(total) + parseFloat(discount)).toFixed(2);
    if (ticket_total > 0) {
      ticket_total = ticket_total.toFixed(2);
      ticket_price = ticket_price.toFixed(2);
    }
    if (hotel_total > 0) {
      hotel_total = hotel_total.toFixed(2);
    }
    if (pageLoaded) {
      event_id = event_details.event_id;
    }
    return (
      <>
        <BookingHeader progress={2} />
        <Container className="main-content">
          <Row className="mt-5">
            <Col>
              <AboutButton
                package_id={package_detail.package_id}
                package_name={package_detail.package_name}
                event_id={event_id}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={9} lg={9}>
              <p className="participant-details font-weight-bold">
                Place Your order
              </p>
            </Col>
          </Row>
          {this.state.paymentSuccessfull && (
            <Message type="success">done</Message>
          )}
          {!this.state.pageLoaded ? (
            <Loader />
          ) : (
            <Row className="justify-content-md-center">
              <Col xs={12} sm={12} md={10} lg={10}>
                <Card>
                  <EventTab progress={2}>
                    <Card.Body>
                      <Row className="justify-content-center mt-4">
                        <Col>
                          {this.state.participant_detail.map(
                            (participant, index) => {
                              return (
                                <p className="ticket-caption mb-0" key={index}>
                                  {participant.first_name}
                                </p>
                              );
                            }
                          )}
                          {this.state.data.is_bill_for_org && (
                            <div className="ticket-caption mt-4">
                              <p className="mb-0 font-weight-bolder">
                                {this.state.org_detail.billing_org_name},
                              </p>
                              <p className="mb-0">
                                {this.state.org_detail.billing_address},
                              </p>
                              {this.state.participant_detail.map(
                                (participant, index) => {
                                  return (
                                    <p className="mb-0" key={index}>
                                      {participant.email_id},
                                    </p>
                                  );
                                }
                              )}
                              <p className="mb-0">
                                GSTIN:{this.state.org_detail.billing_gst_number}
                              </p>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="ticket-caption mb-0 mt-5">
                            {this.state.event_details.event_name}
                          </p>
                          <hr />
                          <table className="payment_table">
                            <tbody>
                              <tr>
                                <td>&nbsp;</td>
                                <td className="ticket-caption pb-2">PRICE</td>
                                <td className="ticket-caption pb-2">
                                  QUANTITY
                                </td>
                                <td className="ticket-caption float-right pb-2">
                                  TOTAL
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span style={{ textTransform: "uppercase" }}>
                                    {this.state.package_detail.package_name}
                                    &nbsp;
                                  </span>
                                  Registration Participants (
                                  {this.state.participant_detail.length})
                                </td>
                                <td>
                                  &#8377;{" "}
                                  {Number(ticket_price).toLocaleString("en-IN")}{" "}
                                </td>
                                <td className="text-center">
                                  {this.state.participant_detail.length}
                                </td>
                                <td className="float-right">
                                  &#8377;{" "}
                                  {Number(ticket_total).toLocaleString("en-IN")}
                                </td>
                              </tr>

                              {this.state.participant_detail.map(
                                (participant, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{participant.first_name}</td>
                                    </tr>
                                  );
                                }
                              )}

                              <tr>
                                <td colSpan="4">
                                  <hr />
                                </td>
                              </tr>
                              {this.state.hotel_detail.length > 0 ? (
                                <tr>
                                  <td>
                                    {`Hotel ${this.state.hotel_detail[0].address}`}
                                  </td>
                                  <td />
                                  <td className="text-center">
                                    {" "}
                                    {this.state.hotel_detail.length}
                                  </td>
                                  <td className="float-right">
                                    &#8377;{" "}
                                    {Number(hotel_total).toLocaleString(
                                      "en-IN"
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                              <tr>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td colSpan="12">
                                  <div
                                    className="discount"
                                    style={{
                                      marginBottom: "10px",
                                      marginLeft: "-10px",
                                      marginRight: "-10px",
                                    }}
                                  >
                                    <p
                                      style={{ gridColumn: "1/-1" }}
                                      className="b"
                                    >
                                      Discount
                                    </p>
                                    <div className="discount_block">
                                      <Form
                                        onSubmit={(e) => this.handleSubmit(e)}
                                      >
                                        <Form.Row className="discount_grid">
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                            className="form-group"
                                          >
                                            <Form.Control
                                              className={
                                                this.state.validationError
                                                  ? "form-control disabled_control error_border"
                                                  : "form-control disabled_control"
                                              }
                                              type="text"
                                              disabled={this.state.needClear}
                                              name="discount"
                                              value={this.state.discount}
                                              onChange={(e) => {
                                                this.setState({
                                                  discount: e.target.value,
                                                });
                                              }}
                                            />
                                            <Form.Label className="notRequired-placeholder">
                                              Discount Code
                                            </Form.Label>
                                            {this.state.validationError &&
                                              (this.state.errorMessage ? (
                                                <p
                                                  className="f3 mt-1"
                                                  style={{ color: "#dc3545" }}
                                                >
                                                  {this.state.errorMessage}
                                                </p>
                                              ) : (
                                                <p
                                                  className="f3 mt-1"
                                                  style={{ color: "#dc3545" }}
                                                >
                                                  Enter valid discount code
                                                </p>
                                              ))}
                                          </Form.Group>

                                          <div className="discount_apply">
                                            {this.state.needClear ? (
                                              <Button
                                                variant="link"
                                                className="f3 b"
                                                type="button"
                                                id="discount"
                                                onClick={() =>
                                                  this.setState({
                                                    discount: "",
                                                    discountAmount: 0,
                                                    needClear: false,
                                                  })
                                                }
                                              >
                                                CLEAR
                                              </Button>
                                            ) : (
                                              <Button
                                                disabled={
                                                  this.state.isProcessing
                                                }
                                                variant="link"
                                                className="f3 b"
                                                type="submit"
                                                id="discount"
                                                onClick={this.onDiscount}
                                              >
                                                {this.state.isProcessing && (
                                                  <img
                                                    src={rolling}
                                                    style={{
                                                      display: "inline-block",
                                                      marginTop: "-2px",
                                                    }}
                                                    alt="rolling"
                                                  ></img>
                                                )}
                                                &nbsp;APPLY
                                              </Button>
                                            )}
                                          </div>
                                        </Form.Row>
                                      </Form>
                                    </div>
                                    <div className="discount_value">
                                      {this.state.discountAmount > 0 && "-"}
                                      &nbsp;&#8377;{" "}
                                      {Number(
                                        this.state.discountAmount
                                      ).toLocaleString("en-IN")}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bolder ticket-caption">
                                  Sub Total
                                </td>
                                <td colSpan="2" />
                                <td className="font-weight-bolder ticket-caption1 float-right">
                                  &#8377;{" "}
                                  {Number(subtotal).toLocaleString("en-IN")}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4">
                                  <hr />
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bolder ticket-caption">
                                  GST(18%)
                                </td>
                                <td colSpan="2" />
                                <td className="font-weight-bolder ticket-caption1 float-right">
                                  &#8377; {Number(gst).toLocaleString("en-IN")}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4">
                                  <hr />
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bolder ticket-caption">
                                  Gateway Fee
                                </td>
                                <td colSpan="2" />
                                <td className="font-weight-bolder ticket-caption float-right">
                                  &#8377;{" "}
                                  {Number(gatewayFee).toLocaleString("en-IN")}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4">
                                  <hr />
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bolder ticket-caption">
                                  Total
                                </td>
                                <td colSpan="2" />
                                <td className="font-weight-bolder ticket-caption float-right">
                                  &#8377;{" "}
                                  {Number(total).toLocaleString("en-IN")}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <p className="f3 b">
                                    Final amount to be paid
                                  </p>
                                </td>
                                <td colSpan="2" />
                                <td>
                                  <p className="f5 b float-right">
                                    &#8377;&nbsp;
                                    {Number(final_amount).toLocaleString(
                                      "en-IN"
                                    )}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr />
                        </Col>
                      </Row>
                    </Card.Body>
                  </EventTab>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
        <div className="mt-5 custom-footer">
          <Container>
            <Row className="pt-4 justify-content-md-center">
              <Col xs={12} sm={12} md={10} lg={10}>
                <div style={{ display: "flex", float: "right" }}>
                  <Link
                    to={
                      "/edit-participants" +
                      "/" +
                    
                      this.props.match.params.booking_code
                    }
                    className="align_button_back"
                    style={{ display: "block" }}
                  >
                    <Button variant="light" className="btn-custom mt-2">
                      BACK
                    </Button>
                  </Link>
                  <Nav.Link href="#">
                    <Button
                      variant="primary"
                      onClick={this.onPaymentClicked}
                      className="btn-custom btn-primary"
                      disabled={
                        this.state.is_payment_clicked ||
                        this.state.paymentSuccessfull
                      }
                    >
                      {this.state.is_payment_clicked
                        ? "Processing . . . "
                        : this.state.paymentSuccessfull
                        ? "Payment Success! wait... "
                        : "PLACE YOUR ORDER"}
                    </Button>
                  </Nav.Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
