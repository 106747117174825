import React from 'react';
import axios from "../../../server/axios";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Loader } from './../../common/Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default class ParticipantDetailDashboard extends React.Component {

    state = {
        booking_details: {},
        pageLoaded: false,
        showAllTransaction: false
    }

    componentDidMount() {
        axios({
            method: "GET",
            url: "/organizer/get-booking/" + this.props.match.params.booking_code,
            headers: {
                "Access-Token": localStorage.accessToken
            }
        })
            .then(response => {
                console.log(response.data)
                this.setState({ booking_details: response.data.booking_details, pageLoaded: true })
            })
            .catch(error => {
                if (error) {
                    if (error.response) {
                        console.log(error.response);
                    }
                }
            });
    }

    render() {

        function bookingStatus(i) {
            switch (i) {
                case 1: return "Booking Pending"
                    break;
                case 2: return "Booking Pending"
                    break;
                case 3: return "Booking Confirmed"
                    break;
                case 4: return "Booking Failed"
                    break;
                case 5: return "Booking Canceled"
                    break;
                default: return "No status found"
                    break;

            }
        }
        function paymentStatus(i) {
            switch (i) {
                case 1: return "Payment Pending"
                    break;
                case 2: return "Payment Success"
                    break;
                case 3: return "Payment Failed"
                    break;
                case 4: return "Payment  link Canceled"
                    break;
                case 5: return "Payment link Expired"
                    break;
                default: return "No status found6"
                    break;

            }
        }

        const data = this.state.booking_details;

        return (
            <>

                {this.state.pageLoaded === false && <Loader></Loader>}
                {this.state.pageLoaded &&

                    <div className="dashboard_container mb-5">

                        <p className="f3 pt-4"> <b>Event Name :</b> {data.event_name}</p>
                        <Row className="mt-3">

                            <Col md={5}><Row>
                                <Col md={5}><p className="f2 b">Booking Id: </p></Col>
                                <Col><p className="f2">{data.booking_unique_code}</p></Col>
                            </Row></Col>
                            <Col md={7}><Row>
                                <Col md={3}><p className="f2 b">Booking Status: </p></Col>
                                <Col><p className="f2">{bookingStatus(data.booking_status)}</p></Col>
                            </Row></Col>

                            <Col md={5}><Row>
                                <Col md={5}><p className="f2 b">Payment status: </p></Col>
                                <Col><p className="f2">{data.is_payment_completed ? "Payment completed" : "Payment not completed "}</p></Col>
                            </Row></Col>
                            <Col md={7}>
                                {data.is_payment_completed && <Row>
                                    <Col md={3}><p className="f2 b">Payment Gateway Id: </p></Col>
                                    <Col><p className="f2">{data.success_payment_details.payment_gateway_id === null ? " - " : data.success_payment_details.payment_gateway_id}</p></Col>
                                </Row>}</Col>

                            <Col md={5}><Row>
                                <Col md={5}><p className="f2 b">Package Name: </p></Col>
                                <Col><p className="f2">{data.package_name}</p></Col>
                            </Row></Col>
                            <Col md={7}><Row>
                                <Col md={3}><p className="f2 b">Ticket Name: </p></Col>
                                <Col><p className="f2">{data.ticket_name}</p></Col>
                            </Row></Col>

                        </Row>
                        <p className="mt-3 f3 b">Participant Details :</p>
                        <Card className="pl-2 pb-2" style={{ width: "90%" }}>
                            <Row className="f2 mt-2">
                                <Col md={2}><b>Sr. no.</b></Col>
                                <Col><b>Name</b></Col>
                                <Col><b>Email Id</b></Col>
                                <Col><b>Contact No.</b></Col>
                            </Row>

                            {data.attendees.map((each, index) => {

                                return (<Row className="f2" key={index}>
                                    <Col md={2}>{index + 1}</Col>
                                    <Col>{each.first_name}</Col>
                                    <Col>{each.email_id}</Col>
                                    <Col>{each.phone_number}</Col>
                                </Row>
                                )
                            })}

                        </Card>
                        {!(data.hotel_bookings.length === 0) && <>
                            <p className="mt-3 f3 b">Hotel Details :</p>
                            <Row>
                                {data.hotel_bookings.map((each, index) => {
                                    const guests_info = JSON.parse(each.guests_info);
                                    console.log(guests_info)
                                    return (

                                        <Col key={index} className="f2" md={6}>
                                            <Card className="shadow pl-3 py-2" >
                                                <p><b>Hotel : </b>{each.event_hotel_name}</p>
                                                <p><b>Room {index + 1} [{each.room_type === 1 ? "Single Occupancy" : "Double Occupancy"}]</b></p>
                                                <Row>
                                                    <Col md={5}><b>Guest Names :</b></Col>
                                                    <Col md={7}>{guests_info.map((guest, index) => {
                                                        return (
                                                            <p className="pb-0 mb-0" key={index}>{guest}</p>
                                                        )
                                                    })}</Col>
                                                    <Col md={5}><b>Total Nights:</b></Col>
                                                    <Col md={7}>{each.total_nights}</Col>
                                                    <Col md={5}><b>Check In:</b></Col>
                                                    <Col md={7}>{each.check_in}</Col>
                                                    <Col md={5}><b>Check Out:</b></Col>
                                                    <Col md={7}>{each.check_out}</Col>
                                                </Row>
                                            </Card>
                                        </Col>

                                    )
                                })}
                            </Row>
                        </>}


                        {data.is_payment_completed && <>
                            <p className="f3 pt-4"> <b>Payment Details :</b> </p>
                            <Row className="mt-3">

                                <Col md={6}><Row>
                                    <Col md={5}><p className="f2 b">Payment Free: </p></Col>
                                    <Col><p className="f2">{data.success_payment_details.is_it_free ? "Yes" : "No"}</p></Col>
                                </Row></Col>
                                {!data.success_payment_details.is_it_free &&
                                    <Col md={6}><Row>
                                        <Col md={5}><p className="f2 b">Payment Gateway Id:</p></Col>
                                        <Col><p className="f2">{data.success_payment_details.payment_gateway_id}</p></Col>
                                    </Row></Col>}

                                <Col md={6}><Row>
                                    <Col md={5}><p className="f2 b">Coupon applied:</p></Col>
                                    <Col><p className="f2">{data.success_payment_details.is_coupon_applied ? "Yes" : "No"}</p></Col>
                                </Row></Col>

                                {data.success_payment_details.is_coupon_applied && <>
                                    <Col md={6}><Row>
                                        <Col md={5}><p className="f2 b">Coupon Code:</p></Col>
                                        <Col><p className="f2">{data.success_payment_details.coupon_code}</p></Col>
                                    </Row></Col>

                                    <Col md={6}><Row>
                                        <Col md={5}><p className="f2 b">Coupon Value: </p></Col>
                                        <Col><p className="f2">&#8377;  {data.success_payment_details.coupon_value}</p></Col>
                                    </Row></Col>
                                </>
                                }

                                <Col md={6}><Row>
                                    <Col md={5}><p className="f2 b">Paid Amount: </p></Col>
                                    <Col><p className="f2">&#8377;  {data.success_payment_details.amount / 100}</p></Col>
                                </Row></Col>

                                <Col md={6}>

                                    <Row>
                                        <Col md={5}><p className="f2 b">Bill in the name of organization: </p></Col>
                                        <Col><p className="f2">{data.is_bill_for_org ? "Yes" : "No"}</p></Col>
                                    </Row>

                                </Col>
                                {/* biling info */}
                                {data.is_bill_for_org &&
                                    <Card className="pt-2">
                                        <Col md={12}>

                                            <Row>
                                                <Col md={6}> <Row>
                                                    <Col md={5}><p className="f2 b">Organization Name: </p></Col>
                                                    <Col><p className="f2">{data.billing_info.billing_org_name}</p></Col>
                                                </Row></Col>
                                                <Col md={6}> <Row>
                                                    <Col md={5}><p className="f2 b">GST Number: </p></Col>
                                                    <Col><p className="f2">{data.billing_info.billing_gst_number}</p></Col>
                                                </Row></Col>
                                                <Col md={6}> <Row>
                                                    <Col md={5}><p className="f2 b">Organization address: </p></Col>
                                                    <Col><p className="f2">{data.billing_info.billing_address}</p></Col>
                                                </Row></Col>
                                            </Row>


                                        </Col>
                                    </Card>
                                }


                            </Row>

                        </>
                        }
                        <Col md={6}>
                            {this.state.showAllTransaction === false ?
                                <p onClick={() => {
                                    this.setState({ showAllTransaction: true })
                                }} className="f2 b  my-2 px-2" style={{ backgroundColor: "#2e333c", color: "white", width: "fit-content", cursor: "pointer", borderRadius: "4px" }}><FontAwesomeIcon icon={faEye} size="sm" color="lightseagreen" />  Show All Attempted Transaction </p>
                                : <p onClick={() => {
                                    this.setState({ showAllTransaction: false })
                                }} className="f2 b  my-2 px-2" style={{ backgroundColor: "#2e333c", color: "white", width: "fit-content", cursor: "pointer", borderRadius: "4px" }}> <FontAwesomeIcon icon={faEyeSlash} size="sm" color="lightseagreen" />  Hide All Attempted Transaction </p>
                            }
                        </Col>
                        {/* all transaction */}
                        {this.state.showAllTransaction &&
                            <Row>
                                <Card style={{ width: "100%" }} className="pl-2 py-2 f2">
                                    {data.total_payments_list.length > 0 ?
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th>Payment Gateway Id</th>
                                                    <th>Is coupon applied</th>
                                                </tr>
                                            </thead>

                                            <tbody>


                                                {
                                                    data.total_payments_list.map((each, index) => {
                                                        const couponApplied = each.is_coupon_applied ? "Yes" : "No";
                                                        return (

                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>&#8377; {each.amount}</td>

                                                                <td>{paymentStatus(each.payment_status)}</td>

                                                                <td>{each.payment_gateway_id === null ? " - " : each.payment_gateway_id}</td>

                                                                <td>{couponApplied}</td></tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>
                                        :
                                        <p className="mx-3">No transaction available</p>
                                    }
                                </Card>
                            </Row>
                        }
                    </div>


                }
            </>)
    }
}