import React, { Component } from "react";
import Api from "../../server/Apis";
import BookingHeader from "../common/BookingHeader";
import { Loader } from "../common/Loader";
import Footer from "../common/Footer";
import { Col, Container, Button, Nav, Row } from "react-bootstrap";
import EditHotelDetails from "./EditHotelDetails";
import Summary from "../selectParticipants/Summary";
import HotelSummary from "../bookHotel/HotelSummary";
import { Redirect, Link } from "react-router-dom";
import { formatDate2 } from "../common/Dates";
import AboutButton from "../common/AboutButton";
import axios from "../../server/axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
export default class EditHotel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingDetails: [],
      pageLoadedBooking: false,
      pageLoadedHotel: false,
      hotelDetails: [],
      room_cnt: 0,
      rooms: [],
      disabled: false,
      redirect: false,
      btn_next: "NEXT",
    };
    this.SelectRooms = React.createRef();
  }
  componentDidMount = () => {
    this.getBookingDetails();
    this.getHotelDetails();
  };
  getBookingDetails = () => {
    let url = Api.getBookingDetails.replace(
      "<booking_unique_code>",
      this.props.match.params.booking_code
    );
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong ...");
        }
      })
      .then((data) => {
        this.setState({
          bookingDetails: data,
          pageLoadedBooking: true,
          room_cnt: data.booking_details.hotel_booking_details.length,
          rooms: data.booking_details.hotel_booking_details,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getHotelDetails = () => {
    let url = Api.getHotelDetails.replace(
      "<event_id>",
      this.props.match.params.event_id
    );
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong ...");
        }
      })
      .then((data) => {
        this.setState({ hotelDetails: data, pageLoadedHotel: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  roomDetails = (data) => {
    this.setState({
      rooms: data,
    });
  };
  removeRoomPanel = (event) => {
    if (this.state.room_cnt > 0) {
      this.setState({
        room_cnt: this.state.room_cnt - 1,
      });
    }
  };
  addRoomPanel = (event) => {
    this.setState({
      room_cnt: this.state.room_cnt + 1,
    });
  };
  submitRoomDetails = (event) => {
    this.SelectRooms.current.validateRoomDetails();
    let data = {
      booking_unique_code: this.props.match.params.booking_code,
      rooms_required: this.state.rooms.length,
      rooms_details: this.state.rooms,
    };
    if (this.state.rooms.length >= 0) {
      this.setState({
        btn_next: "Processing..",
        disabled: true,
      });

      let url = Api.bookHotel.replace(
        "<booking_id>",
        this.props.match.params.booking_code
      );

      axios({
        url: url,
        method: "POST",
        data: data,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          this.setState({
            pageLoaded: false,
            redirect: true,
          });
        })
        .catch((error) => {
          this.setState({
            btn_next: "NEXT",
            disabled: false,
          });
          if (error.response.data.message === "event_hotel_is_not_available") {
            NotificationManager.error("Rooms are not available", "Oops!", 5000);
          }
        });

      // fetch(url, {
      //     method: "post",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify(data)
      // })
      //     .then(response => {
      //         if (response.ok) {
      //             return response.json();
      //         } else {
      //             throw new Error("Something went wrong ...");
      //         }
      //     })
      //     .then(data => {
      //         if (
      //             data.message !== undefined &&
      //             data.message === "success" &&
      //             (data.status !== undefined && data.status === true)
      //         ) {
      //             this.setState({
      //                 pageLoaded: false,
      //                 redirect: true
      //             });
      //         }
      //     })
      //     .catch(error => {
      //         this.setState({
      //             btn_next: 'NEXT',
      //             disabled: false
      //         })
      //         console.log(error);
      //     });
    }
  };
  render() {
    const {
      room_cnt,
      rooms,
      bookingDetails,
      hotelDetails,
      disabled,
      redirect,
      pageLoadedHotel,
      pageLoadedBooking,
    } = this.state;
    const { booking_code } = this.props.match.params;
    let disabled_btn = true;
    if (room_cnt === rooms.length && disabled === false) {
      disabled_btn = false;
    } else {
      disabled_btn = true;
    }
    let redirect_book_page = false;
    if (
      pageLoadedBooking === true &&
      pageLoadedHotel === true &&
      bookingDetails.booking_details.hotel_booking_details.length <= 0
    ) {
      redirect_book_page = true;
    }
    let ticket_amount = 0,
      amount = 0,
      price_details,
      participants,
      gst = 0,
      gateway_fee = 0,
      booking_details = {},
      room_data = [],
      hotel_name = "",
      redirect_ticket_page = false;
    if (pageLoadedBooking === true && pageLoadedHotel === true) {
      price_details = bookingDetails.booking_details.ticket_details;
      participants = bookingDetails.booking_details.attendees_details.length;
      amount = (price_details.price * participants).toFixed(2);
      gst = (parseFloat(amount) * 0.18).toFixed(2);
      gateway_fee = ((parseFloat(amount) + parseFloat(gst)) * 0.04).toFixed(2);
      ticket_amount = (
        parseFloat(amount) +
        parseFloat(gst) +
        parseFloat(gateway_fee)
      ).toFixed(2);
      booking_details = bookingDetails.booking_details.ticket_details;
      booking_details.package_name =
        bookingDetails.booking_details.package_details.package_name;
      booking_details.ticket_description =
        bookingDetails.booking_details.ticket_details.ticket_description;
      hotel_name = hotelDetails.event_hotels[0].event_hotel_name;
      room_data = rooms.map((el, index) => {
        el.id = index;
        el.event_hotel_id = 1;
        return el;
      });
      if (bookingDetails.booking_details.booking_status === 3) {
        redirect_ticket_page = true;
      }
    }
    return (
      <>
        {redirect_book_page && (
          <Redirect
            to={
              "/book-hotel/" +
              bookingDetails.booking_details.event_details.event_id +
              "/" +
              this.props.match.params.booking_code
            }
          />
        )}
        {redirect_ticket_page && (
          <Redirect to={"/getticket/" + this.props.match.params.booking_code} />
        )}
        {redirect && <Redirect to={"/payment/" + booking_code} />}
        {pageLoadedBooking === true && pageLoadedHotel === true ? (
          <>
            <BookingHeader progress={1} />
            <Container className="main-content">
              <Row className="mt-5">
                <Col>
                  <AboutButton
                    package_id={
                      bookingDetails.booking_details.package_details.package_id
                    }
                    package_name={
                      bookingDetails.booking_details.package_details
                        .package_name
                    }
                    event_id={
                      bookingDetails.booking_details.event_details.event_id
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={12} md={9} lg={9} className="mt-2">
                  <p className="participant-details f6 eb">Hotel - Details</p>
                  <EditHotelDetails
                    attendees={bookingDetails.booking_details.attendees_details}
                    hotel_name={
                      hotelDetails.event_hotels[0].event_hotel_address
                    }
                    hotelinfo={hotelDetails.event_hotels[0].rooms}
                    event_details={bookingDetails.booking_details.event_details}
                    roomDetails={this.roomDetails.bind(this)}
                    ref={this.SelectRooms}
                    rooms={rooms}
                    addRoomPanel={this.addRoomPanel.bind(this)}
                    removeRoomPanel={this.removeRoomPanel.bind(this)}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} className="mt-4">
                  <Summary
                    price_details={booking_details}
                    participants={
                      bookingDetails.booking_details.attendees_details.length
                    }
                  />
                  <HotelSummary
                    hotels_info={hotelDetails.event_hotels[0].rooms}
                    hotel_name={hotel_name}
                    description={booking_details.ticket_description}
                    booking_details={room_data}
                    ticket_amount={ticket_amount}
                    location={
                      bookingDetails.booking_details.event_details.location
                    }
                  />
                </Col>
              </Row>
            </Container>
            <Footer>
              <>
                <Link to={"/edit-participants/" + booking_code}>
                  <Button variant="light" className="btn-custom mt-2">
                    BACK
                  </Button>
                </Link>
                <Nav.Link href="#">
                  <Button
                    variant="primary"
                    className="btn-custom"
                    disabled={disabled_btn}
                    onClick={this.submitRoomDetails.bind(this)}
                  >
                    {this.state.btn_next}
                  </Button>
                </Nav.Link>
              </>
            </Footer>
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}
