import React, { Component } from "react";
import Api from "../../server/Apis";
import { Loader } from "../common/Loader";
import BookingHeader from "../common/BookingHeader";
import { Row, Col, Container, Button, Nav } from "react-bootstrap";
import EditParticipantDetails from "./EditParticipantDetails";
import Summary from "../selectParticipants/Summary";
import OrganisationDetails from "../selectParticipants/OrganisationDetails";
import Footer from "../common/Footer";
import { formatDate2 } from "../common/Dates";
import { Redirect } from "react-router";
import AboutButton from "../common/AboutButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "../../server/axios";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

export default class EditParticipants extends Component {
  constructor(props) {
    super(props);
    this.OrganisationDetails = React.createRef();
    this.SelectParticipants = React.createRef();
    this.state = {
      response: [],
      pageLoaded: false,
      disabled: false,
      participants: [],
      participant_cnt: 0,
      validated: false,
      redirect: false,
      btn_next: "NEXT"
    };
  }
  componentDidMount = () => {
    let url = Api.getBookingDetails.replace("<booking_unique_code>", this.props.match.params.booking_code);
    fetch(url)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          response: data,
          pageLoaded: true,
          participant_cnt: data.booking_details.attendees_details.length,
          participants: data.booking_details.attendees_details
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  participantDetails = data => {
    this.setState({
      participants: data
    });
  };
  removeParticipants = event => {
    if (this.state.participant_cnt > 1) {
      this.setState({
        participant_cnt: this.state.participant_cnt - 1
      });
    }
  };
  addParticipants = event => {
    this.setState({
      participant_cnt: this.state.participant_cnt + 1
    });
  };
  submitParticipantDetails = event => {
    let org_details = this.OrganisationDetails.current.getOrganisationDetails();
    this.SelectParticipants.current.validateParticipantDetails();
    let form_valid = false;
    if (org_details.checked) {
      if (org_details.billing_org_name !== "" && org_details.billing_address !== "") {
        form_valid = true;
      } else {
        form_valid = false;
      }
    } else {
      form_valid = true;
    }
    if (form_valid) {
      this.setState({
        btn_next: "Processing..",
        disabled: true
      });
      const { booking_details } = this.state.response;
      let data = {
        booking_unique_code: this.props.match.params.booking_code,
        event_id: parseInt(booking_details.event_details.event_id),
        package_id: parseInt(booking_details.package_details.package_id),
        ticket_id: parseInt(booking_details.ticket_details.ticket_id),
        attendees_details: this.state.participants,
        is_bill_for_org: org_details.checked,
        billing_org_name: org_details.billing_org_name,
        billing_gst_number: org_details.billing_gst_number,
        billing_address: org_details.billing_address
      };

      //murali & naveen  axios only  june 3 2019
      axios({
        method: "POST",
        url: "/events/gettingstarted",
        headers: { "Content-Type": "application/json" },
        data: data
      })
        .then(response => {
          if (
            response.data.message !== undefined &&
            response.data.message === "success" &&
            (response.data.status !== undefined && response.data.status === true)
          ) {
            this.setState({
              pageLoaded: false,
              redirect: true,
              disabled: true,
              bookingCode: response.data.booking_unique_code
            });
          }
        })
        .catch(error => {
          this.setState({ btn_next: "NEXT", disabled: false });
          if (error.response) {
            if (error.response.data) {
              switch (error.response.data.message) {
                case "event_not_valid":
                  this.setState({ note: "Event is expired" });
                  break;
                case "package_not_valid":
                  this.setState({ note: "package is expired" });
                  break;
                case "ticket_not_valid":
                  this.setState({ note: "Ticket is expired" });
                  break;
                case "is_primary_guest_not_found":
                  this.setState({ note: "is_primary_guest_not_found" });
                  break;
                case "attendees_details_not_valid":
                  this.setState({ note: "participant details are not valid please check again" });
                  break;
                case "billing_details_not_found_or_not_valid":
                  this.setState({ note: "billing details are not valid" });
                  break;
                case "email_id_repeated":
                  console.log("ho");
                  NotificationManager.error("Email ids should be unique for participants", "Oops!", 5000);
                  break;
              }
            } else {
              NotificationManager.error("Something went wrong!", "Oops!", 5000);
            }
          } else {
            NotificationManager.error("Something went wrong!", "Oops!", 5000);
          }
        });
    }
  };
  render() {
    let disabled = true;
    if (this.state.participant_cnt === this.state.participants.length && this.state.disabled === false) {
      disabled = false;
    } else {
      disabled = true;
    }
    const { pageLoaded, participant_cnt } = this.state;
    const { booking_details } = this.state.response;
    let price_details = {},
      billing_info = {},
      redirect_ticket_page = false;
    if (pageLoaded) {
      price_details = booking_details.ticket_details;
      price_details.package_name = booking_details.package_details.package_name;
      price_details.ticket_description = booking_details.ticket_details.ticket_description;
      if (booking_details.is_bill_for_org === false) {
        billing_info.billing_org_name = "";
        billing_info.billing_gst_number = "";
        billing_info.billing_address = "";
      } else {
        billing_info.billing_org_name = booking_details.billing_info.billing_org_name;
        billing_info.billing_gst_number = booking_details.billing_info.billing_gst_number;
        billing_info.billing_address = booking_details.billing_info.billing_address;
      }
      booking_details.attendees_details = booking_details.attendees_details.map((el, index) => {
        el.id = index;
        return el;
      });
      if (booking_details.booking_status === 3) {
        redirect_ticket_page = true;
      }
    }
    return (
      <>
        {this.state.redirect && (
          <Redirect
            to={"/payment/"  + this.props.match.params.booking_code}
          />
        )}
        {redirect_ticket_page && <Redirect to={"/getticket/" + this.props.match.params.booking_code} />}
        {pageLoaded ? (
          <>
            <BookingHeader progress={0} />
            <Container className="main-content">
              <Row className="mt-5">
                <Col>
                  <AboutButton
                    package_id={booking_details.package_details.package_id}
                    package_name={booking_details.package_details.package_name}
                    event_id={booking_details.event_details.event_id}
                  />
                </Col>
              </Row>
              <Row className="mb-5 mt-3">
                <Col xs={12} sm={12} md={9} lg={9}>
                  <EditParticipantDetails
                    participants={booking_details.attendees_details}
                    participantDetails={this.participantDetails.bind(this)}
                    removeParticipants={this.removeParticipants.bind(this)}
                    addParticipants={this.addParticipants.bind(this)}
                    ref={this.SelectParticipants}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} className="mt-3">
                  <Summary price_details={price_details} participants={participant_cnt} />
                  <p className="f2 mt-2">
                    <FontAwesomeIcon icon={faInfoCircle} size="lg" color="#007bff" />
                    &nbsp; Have a <b>Discount Code?</b> you are asked to apply during the payment step.
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12} sm={12} md={9} lg={9}>
                  <OrganisationDetails
                    validated={this.state.validated}
                    ref={this.OrganisationDetails}
                    billing_info={billing_info}
                    is_bill_for_org={booking_details.is_bill_for_org}
                  />
                </Col>
              </Row>
            </Container>
            <Footer>
              <>
                <Nav.Link href="/">
                  <Button variant="light" className="btn-custom">
                    BACK
                  </Button>
                </Nav.Link>
                <Nav.Link href="#">
                  <Button
                    variant="primary"
                    disabled={disabled}
                    className="btn-custom"
                    onClick={this.submitParticipantDetails.bind(this)}>
                    {this.state.btn_next}
                  </Button>
                </Nav.Link>
              </>
            </Footer>
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}
