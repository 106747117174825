import React, { Component } from "react";
import Panel from "./../common/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Card } from "react-bootstrap";
export default class UserSetting extends Component {
  render() {
    return (
      <div className="dashboard_container">
        <Row className="pt-5 mt-5 px-4">
          <Col lg={12} md={12} xl={12}>
            <p className="f6 eb">Your Profile</p>
          </Col>
          <Col className="mt-4" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Col className="px-0">
              <Card className="setting_card">
                <Row>
                  <Col xs={12} md={12} lg={8} xl={7}>
                    <p className="f3 b">participant_name</p>
                    <Panel title="Personal Information" />
                    <br />
                    <br />
                    <Panel title="Password" />
                  </Col>
                  <Col xs={12} md={12} lg={4} xl={5}>
                    <p className="f3 b">Profile</p>
                    <Card>
                      <FontAwesomeIcon icon={faUserCircle} size="6x" />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}
