import React from "react";
import AuthHeader from "./../components/common/AuthHeader";
export const PrivacyPolicy = props => {
  return (
    <>
      <AuthHeader />
      <div className="pt-4" id="privacy" >
        <br />
        <h2 dir="ltr">Privacy Policy</h2>
        <p dir="ltr">
          <font size="3">
            <b>GoEventum's Privacy Policy Statement</b>
          </font>
        </p>
        <br />
        <p dir="ltr">
          <font size="3">
            <b>Purpose of GoEventum's Privacy Policy - </b>
          </font>
          Our privacy statement explains the ways we collect any information and how we use it and our commitment to
          respect your information.
        </p>
        <h3 dir="ltr">Privacy for the organiser</h3>
        <p dir="ltr">
          We collect information related to your event through ‘do it yourself’ mode. The information you provide to
          host your event includes your organization details, details of the representative, details of the event and
          pricing for your event. Through the process, information related to saleability, etc of your event is
          collected. As part of the process, we also collect information of your Bank account, Tax identification
          number, etc., to facilitate payments. Sometimes we might also collect your information from external agencies
          or websites.
        </p>
        <p dir="ltr">
          In order to access features and services of GoEventum, You must register and obtain a personalised
          username(your email id) and password. Your personal information can only be retrieved by supplying the correct
          email id and password linked to your profile.
        </p>
        <p dir="ltr">
          We understand that your privacy is important, and therefore, we endeavour to keep your personal information
          secured. The personal information of each organiser is stored on servers that are behind a firewall and are
          housed within secured data centres provided by established global players.
        </p>
        <p dir="ltr">
          We use the data collected only to improve features, research and to facilitate secured payments.
        </p>
        <p dir="ltr">
          Our internal policies and systems protect your privacy by limiting unauthorized employee access to use of your
          personal information.
        </p>
        <h3 dir="ltr">Privacy for the attendee</h3>
        <p dir="ltr">
          We collect your personal information, location, contact details, interests, etc. As part of our policy, we
          also collect information about your credit and debit cards to facilitate payments. However, we do not store
          your card details. We only use services of established third party players for all payment-related services.
        </p>
        <p dir="ltr">
          As part of our marketing campaigns, we also collect your information from external agencies or websites,
          which, in turn, is used for client servicing and marketing.
        </p>
        <p dir="ltr">
          In order to access features and services of GoEventum, You must register and obtain a personal username(your
          email id) and password. Your personal information can only be retrieved by supplying the correct email id and
          password linked to your profile.
        </p>
        <p dir="ltr">
          We understand that your privacy is important, and therefore, we endeavour to keep your personal information
          secured. The personal information oimport AuthHeader from './../components/common/AuthHeader'; f each attendee
          is stored on servers that are behind a firewall and are housed within secured data centres provided by
          established global players.
        </p>
        <p dir="ltr">We use the data collected to improve our features, research and to facilitate secured payments.</p>
        <p dir="ltr">
          Our internal policies and systems protect your privacy by limiting unauthorized employee access to use of your
          personal information.
        </p>
        <h3 dir="ltr">Cookies</h3>
        <p dir="ltr">
          GoEventum also collects information through the use of cookies. Cookies are anonymous, and unique alphanumeric
          identifiers sent from our website and stored in your web browser while you are browsing. With the use of these
          cookies, we collect your Internet Protocol (IP) address used to connect your computer to internet. We collect
          information such as your browser type and version, operating system and the content you viewed or searched on
          the GoEventum website.
        </p>
        <br />
        <p dir="ltr">Use of personal information</p>
        <br />
        <p dir="ltr">
          We use the information gathered to provide better service that is aligned to individual preferences. We also
          measure traffic patterns to provide individualized content. We generate summary reports based on user data for
          our advertisers but we provide them only anonymous information. We use third party advertisers like to Google,
          Bing to provide ads on our website. You can visit the respective ad websites to know more about their privacy
          policies.
        </p>
        <br />
        <p dir="ltr">
          We may approach you periodically with offers, products and services that may be of interest to you based on
          your usage preferences. If you wish not to be contacted, please unsubscribe from the list. We may require
          users to register to use the website, the information provided will be stored electronically. We may provide
          the opportunity to participate in surveys, contests which are voluntary. But by entering the competition, you
          give us permission to use the personal information to notify the winners, to promote it on the website. If
          these are organized by third party providers, we may prohibit them from using the personal information without
          your consent.
        </p>
        <br />
        <p dir="ltr">
          We may provide forums, reviews on the website and this information is public. We may send emails, product
          notifications via email periodically and the user has the choice to unsubscribe from them at any time. We
          deploy software called Cookies to better understand how users interact with the website. We may have relations
          with various advertisers and firms who place their ads on the website. They deploy cookies as well and we have
          no control over the information collected by them and bear no responsibility. We also use web beacons that
          help us deliver content that is of more interest to the individual user. These are embedded in the website and
          we do not tie them to your personal information. We may also provide avenues for interactive services to
          better engage the user and provide a unique experience. We may also provide personal information to law
          enforcement under applicable laws.
        </p>
        <br />
        <p dir="ltr">
          We request you to provide feedback on our services so we can better serve you and this information will not be
          shared with anyone. We provide links to various websites and we are not responsible for the content, privacy
          policies of any third party websites.
        </p>
        <br />
        <p dir="ltr">.</p>
        <h3 dir="ltr">Exclusions</h3>
        <p dir="ltr">
          In the event of you voluntarily disclosing personally identifiable information along with any substantive
          content on your social media page or GoEventum social media page, such activities are beyond the control of
          GoEventum. GoEventum does not monitor or secure such information that can be collected, correlated and used by
          third parties from sources that are not secured. This may also result in unsolicited messages from third
          parties.
        </p>
        <p dir="ltr">
          GoEventum may contain links to other websites that are beyond our control, being a DIY platform. In the event
          of you choosing to access such websites, GoEventum is not responsible for any actions or policies of such
          third parties. We recommend to you to check the privacy policy of such websites before submitting any
          information with them.
        </p>
        <h3 dir="ltr">Children</h3>
        <p dir="ltr">
          GoEventum is a DIY (Do It Yourself) platform and meant for general audience. We do not specifically intend to
          service children under the age of 13 years and hence do not collect any information in this regard, unless it
          is an event for all age groups and is permitted by law. GoEventum usually targets an audience that is over the
          age of 18. If you are a parent or guardian of a child under 13 and believe that he or she has disclosed
          personal information to us, please contact us.
        </p>
        <h3 dir="ltr">Modification</h3>
        <p dir="ltr">
          Our privacy policies may change over time to keep up with regulations, new laws and they will be posted here.
        </p>
        <h3 dir="ltr">Contact</h3>
        <p dir="ltr">
          We always invite your comments and questions about GoEventum website, its services and user interface. Please
          contact us at <a href="mailto:support@goeventum.com">support@goeventum.com</a> for any queries, feedback and
          issues.
        </p>
        <p dir="ltr">
          If at any time you believe that we have not adhered to this Privacy Policy, please write to us. GoEventum will
          put reasonable efforts to promptly investigate and resolve disputes.
        </p>
        <br />
      </div>
    </>
  );
};
