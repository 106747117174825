import React from "react";
import BookingHeader from "./../common/BookingHeader";
import SelectRoom from "./SelectRoom";
import Footer from "../common/Footer";
import Summary from "../selectParticipants/Summary";
import { Col, Container, Button, Nav, Row } from "react-bootstrap";
import HotelSummary from "./HotelSummary";
import Api from "../../server/Apis";
import { formatDate2 } from "../common/Dates";
import { Loader } from "../common/Loader";
import { Redirect, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AboutButton from "../common/AboutButton";
import axios from "../../server/axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

export default class BookHotel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingDetails: [],
      pageLoadedBooking: false,
      pageLoadedHotel: false,
      hotelDetails: [],
      room_cnt: 1,
      rooms: [],
      disabled: false,
      redirect: false,
      btn_next: "NEXT",
    };
    this.SelectRooms = React.createRef();
  }
  componentDidMount = () => {
    this.getBookingDetails();
    this.getHotelDetails();
  };
  getBookingDetails = () => {
    let url = Api.getBookingDetails.replace(
      "<booking_unique_code>",
      this.props.match.params.booking_code
    );
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong ...");
        }
      })
      .then((data) => {
        this.setState({ bookingDetails: data, pageLoadedBooking: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getHotelDetails = () => {
    let url = Api.getHotelDetails.replace(
      "<event_id>",
      this.props.match.params.event_id
    );
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong ...");
        }
      })
      .then((data) => {
        this.setState({ hotelDetails: data, pageLoadedHotel: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  roomDetails = (data) => {
    this.setState({
      rooms: data,
    });
  };
  removeRoomPanel = (event) => {
    if (this.state.room_cnt > 0) {
      this.setState({
        room_cnt: this.state.room_cnt - 1,
      });
    }
  };
  addRoomPanel = (event) => {
    this.setState({
      room_cnt: this.state.room_cnt + 1,
    });
  };
  submitRoomDetails = (event) => {
    this.SelectRooms.current.validateRoomDetails();
    let data = {
      rooms_required: this.state.rooms.length,
      rooms_details: this.state.rooms,
    };
    if (this.state.rooms.length > 0) {
      this.setState({
        btn_next: "Processing..",
        disabled: true,
      });
      let url = Api.bookHotel.replace(
        "<booking_id>",
        this.props.match.params.booking_code
      );
      axios({
        url: url,
        method: "POST",
        data: data,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          this.setState({
            pageLoaded: false,
            redirect: true,
          });
        })
        .catch((error) => {
          this.setState({
            btn_next: "NEXT",
            disabled: false,
          });
          if (error.response.data.message === "event_hotel_is_not_available") {
            NotificationManager.error("Rooms are not available", "Oops!", 5000);
          }
        });

      // fetch(url, {
      //   method: "post",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(data)
      // })
      //   .then(response => {
      //     if (response.ok) {
      //       return response.json();
      //     } else {
      //       throw new Error("Something went wrong ...");
      //     }
      //   })
      //   .then(data => {
      //     if (
      //       data.message !== undefined &&
      //       data.message === "success" &&
      //       (data.status !== undefined && data.status === true)
      //     ) {
      //       this.setState({
      //         pageLoaded: false,
      //         redirect: true
      //       });
      //     }
      //   })
      //   .catch(error => {
      //     this.setState({
      //       btn_next: "NEXT",
      //       disabled: false
      //     });
      //     console.log(error);
      //   });
    } else if (this.state.room_cnt === 0) {
      this.props.history.push(
        "/payment/" + this.props.match.params.booking_code
      );
    }
  };
  render() {
    const {
      room_cnt,
      rooms,
      bookingDetails,
      hotelDetails,
      disabled,
      redirect,
      pageLoadedHotel,
      pageLoadedBooking,
    } = this.state;
    const { event_id, booking_code } = this.props.match.params;
    let disabled_btn = true;
    if (room_cnt === rooms.length && disabled === false) {
      disabled_btn = false;
    } else {
      disabled_btn = true;
    }
    let ticket_amount = 0,
      amount = 0,
      price_details,
      participants,
      gst = 0,
      gateway_fee = 0,
      hotel_name = "",
      booking_details = {},
      redirect_ticket_page = false;
    if (pageLoadedBooking === true && pageLoadedHotel === true) {
      price_details = bookingDetails.booking_details.ticket_details;
      participants = bookingDetails.booking_details.attendees_details.length;
      amount = (price_details.price * participants).toFixed(2);
      gst = parseFloat(amount * 0.18).toFixed(2);
      gateway_fee = ((parseFloat(amount) + parseFloat(gst)) * 0.04).toFixed(2);
      ticket_amount = (
        parseFloat(amount) +
        parseFloat(gst) +
        parseFloat(gateway_fee)
      ).toFixed(2);
      booking_details = bookingDetails.booking_details.ticket_details;
      booking_details.package_name =
        bookingDetails.booking_details.package_details.package_name;
      // booking_details.ticket_description = bookingDetails.booking_details.package_details.package_description;
      booking_details.ticket_description =
        bookingDetails.booking_details.ticket_details.ticket_description;
      if (this.state.pageLoadedHotel) {
        hotel_name = hotelDetails.event_hotels[0].event_hotel_name;
      }
      if (bookingDetails.booking_details.booking_status === 3) {
        redirect_ticket_page = true;
      }
    }
    return (
      <>
        {redirect && <Redirect to={"/payment/" + booking_code} />}
        {redirect_ticket_page && (
          <Redirect to={"/getticket/" + this.props.match.params.booking_code} />
        )}
        {pageLoadedBooking === true && pageLoadedHotel === true ? (
          <>
            <BookingHeader progress={1} />
            <Container className="main-content">
              <Row className="mt-5">
                <Col>
                  <AboutButton
                    package_id={
                      bookingDetails.booking_details.package_details.package_id
                    }
                    package_name={booking_details.package_name}
                    event_id={event_id}
                  />
                </Col>
              </Row>
              <p className="participant-details  mb-0 f6 eb">
                Hotel - Details (optional)
              </p>
              <small className="f2 fd2">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  size="lg"
                  color="#007bff"
                />
                &nbsp; Hotel room booking is optional.If you don't need one, You
                may
                <Link to={"/payment/" + booking_code}>
                  <span style={{ color: "#3e6ce2" }}>
                    <b> skip&nbsp;</b>
                  </span>
                </Link>
                it.
              </small>
              <br />
              <Row className="mt-3">
                <Col xs={12} sm={12} md={9} lg={9}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    className="mb-3"
                  >
                    <Link to={"/payment/" + booking_code}>
                      <Button
                        variant="primary"
                        className="skip_btn btn-custom f3 eb "
                      >
                        SKIP
                      </Button>
                    </Link>
                  </div>
                  <SelectRoom
                    attendees={bookingDetails.booking_details.attendees_details}
                    hotel_name={
                      hotelDetails.event_hotels[0].event_hotel_address
                    }
                    hotelinfo={hotelDetails.event_hotels[0].rooms}
                    event_details={bookingDetails.booking_details.event_details}
                    roomDetails={this.roomDetails.bind(this)}
                    ref={this.SelectRooms}
                    addRoomPanel={this.addRoomPanel.bind(this)}
                    removeRoomPanel={this.removeRoomPanel.bind(this)}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} className="mt-3">
                  <Summary
                    price_details={booking_details}
                    participants={
                      bookingDetails.booking_details.attendees_details.length
                    }
                  />
                  <HotelSummary
                    hotels_info={hotelDetails.event_hotels[0].rooms}
                    hotel_name={hotel_name}
                    description={booking_details.ticket_description}
                    booking_details={rooms}
                    ticket_amount={ticket_amount}
                    location={
                      bookingDetails.booking_details.event_details.location
                    }
                  />
                </Col>
              </Row>
            </Container>
            <Footer>
              <>
                <Link
                  to={
                    "/edit-participants/" + this.props.match.params.booking_code
                  }
                >
                  <Button variant="light" className="btn-custom mt-2">
                    BACK
                  </Button>
                </Link>
                <Nav.Link href="#">
                  <Button
                    variant="primary"
                    className="btn-custom"
                    disabled={disabled_btn}
                    onClick={this.submitRoomDetails.bind(this)}
                  >
                    {this.state.btn_next}
                  </Button>
                </Nav.Link>
              </>
            </Footer>
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}
