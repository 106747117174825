import React, { Component } from "react";
import BookingHeader from "../common/BookingHeader";
import { Row, Col, Container, Button, Nav } from "react-bootstrap";
// import Ticket from "../common/Ticket";
import Summary from "./Summary";
import ParticipantDetails from "./ParticipantDetails";
import OrganisationDetails from "./OrganisationDetails";
import Footer from "../common/Footer";
import Api from "../../server/Apis";
import axios from "../../server/axios";
import { Redirect } from "react-router";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Loader } from "./../common/Loader";
import NotFoundError from "../common/NotFoundError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AboutButton from "../common/AboutButton";
import "react-notifications/lib/notifications.css";

export default class SelectParticipants extends Component {
  constructor(props) {
    super(props);
    this.OrganisationDetails = React.createRef();
    this.SelectParticipants = React.createRef();
    this.state = {
      response: [],
      pageLoaded: false,
      disabled: false,
      participants: [],
      participant_cnt: parseInt(this.props.match.params.participants),
      validated: false,
      redirect: false,
      bookingCode: "",
      btn_next: "NEXT"
    };
  }

  componentDidMount() {
    let url = Api.getTicketDetails
      .replace("<package_id>", this.props.match.params.package_id)
      .replace("<ticket_id>", this.props.match.params.ticket_id);
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong ...");
        }
      })
      .then(data => {
        this.setState({ response: data, pageLoaded: true });
      })
      .catch(error => {
        console.log(error);
      });
  }
  participantDetails = data => {
    this.setState({
      participants: data
    });
  };
  removeParticipants = event => {
    if (this.state.participant_cnt > 1) {
      this.setState({
        participant_cnt: this.state.participant_cnt - 1
      });
    }
  };
  addParticipants = event => {
    this.setState({
      participant_cnt: this.state.participant_cnt + 1
    });
  };

  submitParticipantDetails = event => {
    let org_details = this.OrganisationDetails.current.getOrganisationDetails();
    this.SelectParticipants.current.validateParticipantDetails();
    let form_valid = false;
    if (org_details.checked) {
      if (org_details.billing_org_name !== "" && org_details.billing_address !== "") {
        form_valid = true;
      } else {
        form_valid = false;
      }
    } else {
      form_valid = true;
    }
    if (form_valid) {
      this.setState({
        btn_next: "Processing..",
        disabled: true
      });
      let data = {
        event_id: parseInt(this.props.match.params.event_id),
        package_id: parseInt(this.props.match.params.package_id),
        ticket_id: parseInt(this.props.match.params.ticket_id),
        attendees_details: this.state.participants,
        is_bill_for_org: org_details.checked,
        billing_org_name: org_details.billing_org_name,
        billing_gst_number: org_details.billing_gst_number,
        billing_address: org_details.billing_address
      };
      //murali & naveen  axios only  june 3 2019
      axios({
        method: "POST",
        url: "/events/gettingstarted",
        headers: { "Content-Type": "application/json" },
        data: data
      })
        .then(response => {
          if (
            response.data.message !== undefined &&
            response.data.message === "success" &&
            (response.data.status !== undefined && response.data.status === true)
          ) {
            this.setState({
              pageLoaded: false,
              redirect: true,
              disabled: true,
              bookingCode: response.data.booking_unique_code
            });
          }
        })
        .catch(error => {
          this.setState({ btn_next: "NEXT", disabled: false });
          if (error.response) {
            if (error.response.data) {
              switch (error.response.data.message) {
                case "event_not_valid":
                  this.setState({ note: "Event is expired" });
                  break;
                case "package_not_valid":
                  this.setState({ note: "package is expired" });
                  break;
                case "ticket_not_valid":
                  this.setState({ note: "Ticket is expired" });
                  break;
                case "is_primary_guest_not_found":
                  this.setState({ note: "is_primary_guest_not_found" });
                  break;
                case "attendees_details_not_valid":
                  this.setState({ note: "participant details are not valid please check again" });
                  break;
                case "billing_details_not_found_or_not_valid":
                  this.setState({ note: "billing details are not valid" });
                  break;
                case "email_id_repeated":
                  console.log("ho");
                  NotificationManager.error("Email ids should be unique for participants", "Oops!", 5000);
                  break;
              }
            } else {
              NotificationManager.error("Something went wrong!", "Oops!", 5000);
            }
          } else {
            NotificationManager.error("Something went wrong!", "Oops!", 5000);
          }
        });
    }
  };
  render() {
    let disabled = true;
    if (this.state.participant_cnt === this.state.participants.length && this.state.disabled === false) {
      disabled = false;
    } else {
      disabled = true;
    }
    const { package_ticket_info } = this.state.response;
    const { participants, event_id } = this.props.match.params;
    return (
      <>
        {this.state.redirect && <Redirect to={"/payment/" + this.state.bookingCode} />}
        {this.state.pageLoaded ? (
          <>
            {package_ticket_info[0].ticket_status === 1 && package_ticket_info[0].current_available_count > 0 ? (
              <>
                <BookingHeader progress={0} />
                <NotificationContainer />
                <Container className="main-content">
                  <Row className="mt-5">
                    <Col>
                      <AboutButton
                        package_id={package_ticket_info[0].package_id}
                        package_name={package_ticket_info[0].package_name}
                        event_id={event_id}
                      />
                    </Col>
                  </Row>
                  {/* <Row className="mt-5">
                <Col md={6} lg={3} sm={12} xs={12}>
                  <p className="f3 b">Select Ticket Type</p>
                  <Form className="mt-4">
                    <Form.Row>
                      <Form.Group className="form-group" as={Col} controlId="formTicketType">
                        <Form.Control className="form-control" as="select" defaultValue={package_id}>
                          <option value="1">Elite</option>
                          <option value="2">Professional</option>
                          <option value="3">Standard</option>
                        </Form.Control>
                        <Form.Label className="form-control-placeholder">Ticket Type</Form.Label>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                </Col>
              </Row> */}
                  {/* <Row className="mt-4">
                <Col xs={12} sm={12} md={9} lg={9}>
                  <p className="f3 b ">Select No.of participants</p>
                  <Ticket ticket_details={package_ticket_info[0]} participants={this.state.participant_cnt} disable_select_participant={true} />
                </Col>
              </Row> */}
                  <Row className="mb-5 mt-3">
                    <Col xs={12} sm={12} md={9} lg={9}>
                      <ParticipantDetails
                        participants={participants}
                        participantDetails={this.participantDetails.bind(this)}
                        removeParticipants={this.removeParticipants.bind(this)}
                        addParticipants={this.addParticipants.bind(this)}
                        ref={this.SelectParticipants}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} className="mt-3">
                      <Summary price_details={package_ticket_info[0]} participants={this.state.participant_cnt} />
                      <p className="f2 mt-2">
                        <FontAwesomeIcon icon={faInfoCircle} size="lg" color="#007bff" />
                        &nbsp; Have a <b>Discount Code?</b> you are asked to apply during the payment step.
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} sm={12} md={9} lg={9}>
                      <OrganisationDetails validated={this.state.validated} ref={this.OrganisationDetails} />
                    </Col>
                  </Row>
                </Container>
                <Footer>
                  <>
                    <Nav.Link href="/">
                      <Button variant="light" className="btn-custom">
                        BACK
                      </Button>
                    </Nav.Link>
                    <Nav.Link href="#">
                      <Button
                        variant="primary"
                        disabled={disabled}
                        className="btn-custom"
                        onClick={this.submitParticipantDetails.bind(this)}>
                        {this.state.btn_next}
                      </Button>
                    </Nav.Link>
                  </>
                </Footer>
              </>
            ) : (
                <NotFoundError />
              )}
          </>
        ) : (
            <Loader />
          )}
      </>
    );
  }
}
