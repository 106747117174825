import React from "react";
import AuthHeader from "./../components/common/AuthHeader";
export const CodeOfConduct = props => {
    return (
        <>
        <AuthHeader />
        <div className="conduct">
        
        <div className="conduct_container">
        
        <center className="f6 eb mb-4">Code of conduct</center>
        
        
        <p className="f5 b mb-2">Short Version</p>
        <p className="f3 fd1 mb-5 pb-2">
        We at UXIndia conference are dedicated to providing a harassment-free conference experience for everyone, regardless of gender, gender identity and expression, age, sexual orientation, disability, physical appearance, body size, race, ethnicity, religion (or lack thereof), or technology choices. Sexual language and imagery is not appropriate for any conference venue, including talks. Conference participants violating these rules may be sanctioned or expelled from the conference without a refund at the discretion of the conference organizers.
        <br />
        So we’d expect all the participants to be nice and civil.
        
        </p>
        
        
        <p className="f5 b mb-4 mt-4"> Long Version</p>
        <p className="f4 b mb-1">Non-acceptable behaviors</p>
        <p className="f3 fd1 mb-4">Harassment includes offensive verbal comments related to gender, gender identity and expression, age, sexual orientation, disability, physical appearance, body size, race, ethnicity, religion, technology choices, sexual images in public spaces, deliberate intimidation, stalking, following, harassing photography or recording, sustained disruption of talks or other events, inappropriate physical contact, and unwelcome sexual attention.
        <br />
        Participants asked to stop any harassing behavior are expected to comply immediately.
        </p>
        
        
        
        
        
        <p className="f4 b mb-1">Exhibitors & Sponsors</p>
        <p className="f3 fd1 mb-4"> Exhibitors & Sponsors are also subject to the anti-harassment policy. Exhibitors in the expo hall, sponsor or vendor booths, or similar activities are also subject to the anti-harassment policy. In particular, exhibitors should not use sexualized images, activities, or other material. Booth staff (including volunteers) should not use sexualized clothing/uniforms/costumes, or otherwise create a sexualized environment.</p>
        
        
        
        <p className="f4 b mb-1">Participants</p>
        <p className="f3 fd1 mb-4">If a participant engages in harassing behavior, the conference organizers may take any action they deem appropriate, including warning the offender or expulsion from the conference with no refund.</p>
        
        
        <p className="f4 b mb-1">Need help?</p>
        <p className="f3 fd1 mb-4">At our event, if you are being harassed, notice that someone else is being harassed, or have any other concerns, please contact one of the event organizers or volunteers immediately. They will be clear­ly vis­i­ble, wear­ing ​“Vol­un­teer” T-shirts / or badges.
        <br />
        Conference staff will be happy to help participants contact hotel/venue security or local law enforcement, provide escorts, or otherwise assist those experiencing harassment to feel safe for the duration of the conference. We value your attendance.
        </p>
        
        <p className="f4 b mb-1">Venue & Social Events</p>
        <p className="f3 fd1 mb-4"> We expect participants to follow these rules at conference and workshop venues and conference-related social events.</p>
        
        
        <p className="f4 b mb-1"> License</p>
        <p className="f3 fd1 mb-4">This code of conduct is based on confcodeofconduct.com, licensed under a Creative Commons Attribution 3.0 Unported License.</p>
        
        
        </div>
        
        
        </div>
        </>
        )
    }