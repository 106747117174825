import React from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import axios from "../../../server/axios";
import { Loader } from "./../../common/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import Footer from "./../../common/Footer";
import { NavLink } from "react-router-dom";

export default class DashboardSummary extends React.Component {
  state = {
    summary: {},
    sizePerPage: 10,
    page: 1,
    pageLoaded: false,
    searchResult: false,
    result: {},
    searchInput: "",
    paymentStatus: "",
    columns: [
      {
        dataField: "name",
        text: "Name"
      },
      {
        dataField: "email",
        text: "Email Id"
      },
      {
        dataField: "booking_code",
        text: "Booking Code"
      },
      {
        dataField: "booking_status",
        text: "Booking Status"
      },
      {
        dataField: "created",
        text: "Created"
      }, {
        dataField: "booking_code",
        text: "",
        formatter: (cell, row) => (
          <NavLink target="_blank" to={"/organization/dashboard/summary/participant/" + cell}>view</NavLink>
        )


      }
    ]
  };
  componentDidMount() {
    axios({
      method: "GET",
      url: "organizer/event-summary/" + this.props.match.params.event_id,
      headers: {
        "Access-Token": localStorage.accessToken
      }
    })
      .then(response => {
        this.setState({ summary: response.data, pageLoaded: true });
      })
      .catch(error => {
        if (error) {
          if (error.response) {
            console.log(error.response);
          }
        }
      });

    let paymentStatus = [];
    if (this.state.paymentStatus === "") {
      paymentStatus = [];
    } else {
      paymentStatus = [parseInt(this.state.paymentStatus)];
    }
    // axios({
    //   url: "organizer/" + this.props.match.params.event_id + "/bookings",
    //   method: "POST",
    //   headers: {
    //     "Access-Token": localStorage.accessToken
    //   },
    //   data: {
    //     booking_status: paymentStatus,
    //     search_input: this.state.searchInput,
    //     size: this.state.sizePerPage,
    //     pageno: this.state.page
    //   }
    // })
    //   .then(response => {
    //     this.setState({ searchResult: true, result: response.data });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  onSearch = () => {
    let paymentStatus = [];
    if (this.state.paymentStatus === "") {
      paymentStatus = [];
    } else {
      paymentStatus = [parseInt(this.state.paymentStatus)];
    }
    axios({
      url: "organizer/" + this.props.match.params.event_id + "/bookings",
      method: "POST",
      headers: {
        "Access-Token": localStorage.accessToken
      },
      data: {
        booking_status: paymentStatus,
        search_input: this.state.searchInput,
        size: this.state.sizePerPage,
        pageno: 1
      }
    })
      .then(response => {
        this.setState({ searchResult: true, result: response.data, page: 1 });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    let paymentStatus = [];
    if (this.state.paymentStatus === "") {
      paymentStatus = [];
    } else {
      paymentStatus = [parseInt(this.state.paymentStatus)];
    }
    if (this.state.result.bookings) {
      this.setState({ result: { ...this.state.result, bookings: [] } });
    }
    axios({
      url: "organizer/" + this.props.match.params.event_id + "/bookings",
      method: "POST",
      headers: {
        "Access-Token": localStorage.accessToken
      },
      data: {
        booking_status: paymentStatus,
        search_input: this.state.searchInput,
        size: this.state.sizePerPage,
        pageno: page
      }
    })
      .then(response => {
        this.setState({
          searchResult: true,
          result: response.data,
          page,
          sizePerPage
        });
      })
      .catch(error => {
        console.log(error);
      });
    // setTimeout(() => {
    //   this.setState(() => ({
    //     page,
    //     sizePerPage
    //   }));
    // }, 2000);
  };

  render() {
    let Summary = {};
    let PackageDetails = [];
    if (this.state.pageLoaded) {
      Summary = this.state.summary.event_details;
      PackageDetails = Summary.packages;
    }
    let products = [];
    if (this.state.searchResult) {
      for (let i = 0; i < this.state.result.bookings.length; i++) {
        products.push({
          name: this.state.result.bookings[i].first_name,
          email: this.state.result.bookings[i].email_id,
          booking_code: this.state.result.bookings[i].booking_unique_code,
          booking_status:
            (this.state.result.bookings[i].booking_status === 1 &&
              "Booking Created") ||
            (this.state.result.bookings[i].booking_status === 2 &&
              "Payment Initiated") ||
            (this.state.result.bookings[i].booking_status === 3 &&
              "Payment Completed") ||
            (this.state.result.bookings[i].booking_status === 4 &&
              "Payment Failed") ||
            (this.state.result.bookings[i].booking_status === 5 &&
              "Booking Cancelled"),
          created: this.state.result.bookings[i].created.substring(
            0,
            this.state.result.bookings[i].created.indexOf("T")
          )
        });
      }
    }

    const TableLoader = () => (
      <div>
        <Loader height="24rem" />
      </div>
    );

    const EmptyTable = () => (
      <div>
        <p>No Results Found</p>
      </div>
    );

    const RemotePagination = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize
    }) => (
        <div>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page,
              sizePerPage,
              totalSize
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  remote
                  classes="admin_table"
                  tabIndexCell
                  bootstrap4
                  keyField="booking_code"
                  data={data}
                  columns={this.state.columns}
                  onTableChange={onTableChange}
                  noDataIndication={this.state.result.bookings_count < 1 ? () => <EmptyTable /> : () => <TableLoader />}
                  {...paginationTableProps}
                />
                <div>
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            )}
          </PaginationProvider>
        </div>
      );

    return (
      <>
        {this.state.pageLoaded === false && <Loader />}
        {this.state.pageLoaded && (
          <div className="dashboard_container">
            <Row className=" mt-2">
              <Col lg={12} md={12} xl={12}>
                <p
                  className="back_link px-2"
                  onClick={() =>
                    this.props.history.push("/organization/dashboard")
                  }
                >
                  &larr;&nbsp;My Events
                </p>
                <p className="f6 eb pl-1 px-3">Dashboard</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="shadow summary_card">
                  <p className="fd1 f2 mb-0">Total Revenue</p>
                  <p className="f3 b">
                    &#8377; {Summary.revenue_generated.toFixed(2)}{" "}
                  </p>
                  <p className="fd1 f2 mt-1 mb-0">Balance after taxes</p>
                  <p className="f3 b">
                    {" "}
                    &#8377; {Summary.balance_exc_gst.toFixed(2)}
                  </p>
                </Card>
              </Col>
              <Col>
                <Card className="shadow summary_card">
                  <p className="fd1 f2 mb-0">Total Tickets Sold</p>
                  <p className="f3 b">
                    {Summary.max_quantity - Summary.current_available_count}
                  </p>
                  <p className="fd1 f2 mt-1 mb-0">Total Paid Tickets</p>
                  <p className="f3 b">{Summary.paid_tickets}</p>
                </Card>
              </Col>
              <Col>
                <Card className="shadow summary_card">
                  <p className="fd1 f2 mb-0">GST (18%)</p>
                  <p className="f3 b">
                    &#8377; {Summary.collected_gst.toFixed(2)}
                  </p>
                  <p className="fd1 f2 mt-1 mb-0">Gateway Fee (4%)</p>
                  <p className="f3 b">
                    &#8377; {Summary.collected_gateway.toFixed(2)}
                  </p>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5">
              <Card className="shadow dashboard_ticket_summary">
                <Row>
                  {PackageDetails.map((each, index) => {
                    return (
                      <Col lg={6} xl={6} key={index}>
                        <Row className="mt-2 mx-0 dashboard_ticket_summary__ticket f2">
                          <Col>
                            <Row>
                              <Col>
                                {" "}
                                <p className="mb-0 fd1">
                                  {each.package_name}-{each.ticket_name}
                                </p>
                              </Col>

                              <Col>
                                {each.ticket_status === 1 && (
                                  <div className="golden_pallate F2">
                                    Active
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <p className="mb-0 b">
                              {each.max_quantity - each.current_available_count}
                              /{each.max_quantity}
                            </p>
                          </Col>
                          {/* <Col>
                                        <p className="mb-0 fd1">Amount</p>
                                        <p className="mb-0">&#8377; </p>
                                    </Col> */}
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </Row>

            {/* <Row className="mt-5 px-3">
              <Form>
                <Row>
                  <Col>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="SearchInput"
                      value={this.state.searchInput}
                      onChange={e => {
                        this.setState({ searchInput: e.target.value });
                      }}
                    />
                    <Form.Label className="form-control-placeholder">
                      Booking Code
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      name="paymentStatus"
                      defaultValue={this.state.paymentStatus}
                      onChange={e => {
                        this.setState({ paymentStatus: e.target.value });
                      }}
                    >
                      <option value="">all</option>
                      <option value="1">Booking Initiated</option>
                      <option value="2">Payment Initiated</option>
                      <option value="3">Booking Success</option>
                      <option value="4">Payment Failed</option>
                    </Form.Control>
                  </Col>
                  <Col>
                    <Button
                      variant="link"
                      style={{ marginTop: "-2rem" }}
                      onClick={this.onSearch}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row> */}
            {/* {this.state.searchResult && (
              <RemotePagination
                data={products}
                page={this.state.page}
                sizePerPage={this.state.sizePerPage}
                totalSize={this.state.result.bookings_count}
                onTableChange={this.handleTableChange}
              />
            )} */}
            {/* <Footer /> */}
          </div>
        )}
      </>
    );
  }
}
