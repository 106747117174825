import React from "react";
import Login from "./login";
import Signup from "./signup";
import axios from "../../server/axios";
// import UmoLogo from "../../resources/images/umo-logo.png";
import UmoLogo from "../../resources/images/umo-logo-new.png";

import { Card, Container, Row, Col, Tabs, Tab } from "react-bootstrap";

import Footer from "../common/Footer";
import AuthHeader from "./../common/AuthHeader";
import Message from "./../common/Message";
class auth extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "Login",
      progress: 0,
      forVerification: true,
      responseType: "",
      responseMessage: ""
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.props.match.params.authToken) {
      axios({
        method: "POST",
        url: "/verify_account",
        data: {
          confirm_code: this.props.match.params.authToken
        }
      })
        .then(response => {
          this.setState({
            forVerification: false,
            responseType: "success",
            responseMessage: "Verification  is done, Just login to your account."
          });
        })
        .catch(error => {
          console.log(error.response);
          this.setState({ forVerification: false });
          if (error.response) {
            if (error.response.data) {
              if (error.response.data.code === 403) {
                this.setState({
                  responseType: "error",
                  responseMessage: error.response.data.message
                });
              }
              if (error.response.data.code === 422) {
                this.setState({
                  responseType: "error",
                  responseMessage: "required field is missing, Do check if you are not using secure network."
                });
              }
            }
          }
        });
    } else {
      this.setState({ forVerification: false });
    }
  };

  onNewUser = () => {
    this.setState({ progress: 1 });
  };

  onLogin = () => {
    this.setState({ progress: 0 });
  };

  render() {
    return (
      <>
        <AuthHeader />
        <Container className="responsive">
          <Row className="justify-content-md-center pt-4 pb-2 mt-2 ">
            <Col sm={12} md={10} lg={8} xl={9}>
              {this.state.responseType && (
                <Message type={this.state.responseType}>{this.state.responseMessage}</Message>
              )}
              <br />
              <Card className="shadow responsive">
                <Row className="justify-content-md-center px-4">
                  <Col sm={12} md={10} lg={10} xl={8}>
                    <Tabs activeKey={this.state.key} onSelect={key => this.setState({ key })} className="auth-tab">
                      <Tab eventKey="Login" title="Login" className="custom-auth-tab">
                        <Login forVerification={this.state.forVerification} />
                      </Tab>
                      <Tab eventKey="Signup" title="New user" disabled={this.state.forVerification}>
                        <Signup />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row sm={12} md={10} lg={8} xl={10} className="justify-content-md-center">
            <Col sm={12} md={10} lg={8} xl={9}>
              <Row className="justify-content-md-center">
                <Col sm={12} md={10} lg={10} xl={8}>
                  {this.state.key === "Login" ? (
                    <div className="auth-powered">
                      <small className="f1 fd1">Powered by</small>
                      <br />
                      <img src={UmoLogo} alt="umo_logo" style={{ width: 100 + "px" }} />
                    </div>
                  ) : (
                    <>
                      <br />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {this.state.key === "Signup" && (
          <>
            {" "}
            <br />
            <br />
            <br />
          </>
        )}
        <Footer />
      </>
    );
  }
}
export default auth;
