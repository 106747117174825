import React from "react";
import AuthHeader from "../common/AuthHeader";
import { Form, Col, Button, Card } from "react-bootstrap";

import { Link } from "react-router-dom";
import Message from "./../common/Message";
import axios from "../../server/axios";

export default class forgotPassword extends React.Component {
  state = {
    email_id: "",
    forgotClicked: false,
    validated: false,
    responseMessage: "",
    responseType: ""
  };

  onForgotPasswordClick(e) {
    e.preventDefault();
    if (this.state.forgotClicked === false) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        this.setState({ forgotClicked: false });
        e.stopPropagation();
      } else {
        this.setState({ forgotClicked: true });
        axios({
          method: "POST",
          url: "/forgotpassword",
          data: {
            email_id: this.state.email_id
          }
        })
          .then(response => {
            this.setState({
              forgotClicked: false,
              responseType: "success",
              responseMessage: "Instruction has been sent to your email. "
            });
          })
          .catch(error => {
            console.log(error.response);
            this.setState({ forgotClicked: false });

            if (error.response) {
              if (error.response.data) {
                if (error.response.data.code === 403) {
                  this.setState({
                    responseType: "error",
                    responseMessage: "This email id is not registered with us. "
                  });
                }
              }
            }
          });
      }

      this.setState({ validated: true });
    }
  }

  render() {
    const { validated } = this.state;
    return (
      <>
        <AuthHeader />
        <div className="forgot_container">
          <div className="forgot_content">
            {this.state.responseMessage !== "" && (
              <>
                <Message type={this.state.responseType}>{this.state.responseMessage}</Message>
                <br />
              </>
            )}
            <Card className="f_content">
              <p className=" f6 b">Forgot your password?</p>
              <p className="f3">
                Enter your email address you provided during the registration process below and we'll get you back on
                track
              </p>
              <Form
                noValidate
                validated={validated}
                onSubmit={e => this.onForgotPasswordClick(e)}
                className="pb-5 mt-4">
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="validationCustomLEmail" className="form-group">
                    <Form.Control
                      required
                      type="email"
                      className="form-control"
                      name="email_id"
                      pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      value={this.state.email_id}
                      onChange={e => {
                        this.setState({ email_id: e.target.value });
                      }}
                    />
                    <Form.Label className="form-control-placeholder">Email Address</Form.Label>
                    <Form.Control.Feedback type="invalid">Please Enter Valid Email Address</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Button
                  disabled={this.state.forgotClicked}
                  className="auth-primary mt-2  b custom_button_f"
                  size="lg"
                  type="submit"
                  block>
                  SEND THE RESET PASSWORD INSTRUCTIONS
                </Button>
                <br />
                <p className="f3 b auth-links_clicked" style={{ border: "none" }}>
                  <Link to="/auth">Back to Login</Link>
                </p>
              </Form>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
