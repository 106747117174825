import React, { Component } from "react";
import { Button } from "react-bootstrap";
import EventTab from "../common/EventTab";
import AddRoom from "../bookHotel/AddRoom";
import UpdateRoom from "./UpdateRoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { HotelPopup } from "./../common/HotelPopup";

export default class EditHotelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeIndex: [],
      rooms: this.props.rooms,
      newRoom: false,
      room_panels: [],
      single_room_price: 0,
      double_room_price: 0,
    };
  }
  componentDidMount = () => {
    let addRoom = [];
    this.state.rooms.forEach((element, i) => {
      addRoom.push(
        <UpdateRoom
          key={i}
          id={i}
          removePanel={this.removePanel.bind(this)}
          addRoom={this.addRoom.bind(this)}
          attendees={this.props.attendees}
          hotelinfo={this.props.hotelinfo}
          event_details={this.props.event_details}
          data={element}
        />
      );
    });
    this.setState({
      room_panels: addRoom,
    });
    this.props.hotelinfo.forEach((each) => {
      if (each.room_type === 1) {
        this.setState({ single_room_price: each.price_per_night });
      } else if (each.room_type === 2) {
        this.setState({ double_room_price: each.price_per_night });
      }
    });
  };
  removePanel = (id) => {
    let room_panels = this.state.room_panels;
    if (room_panels.length > 0) {
      this.removeRoom(id);
      let rooms = room_panels.filter((value, index) => {
        if (id !== value.props.id) {
          return value;
        }
      });
      this.setState(
        {
          room_panels: rooms,
        },
        () => {
          this.props.removeRoomPanel();
        }
      );
    }
  };
  addRoom = (data) => {
    this.removeRoom(data.id);
    this.setState(
      (prevState) => ({
        rooms: [...prevState.rooms, data],
      }),
      () => {
        this.props.roomDetails(this.state.rooms);
      }
    );
  };
  removeRoom = (id) => {
    if (id !== undefined) {
      let rooms = this.state.rooms;
      this.setState(
        {
          rooms: rooms.filter((value, index) => {
            if (id !== value.id) {
              return value;
            }
          }),
        },
        () => {
          this.props.roomDetails(this.state.rooms);
        }
      );
    }
  };
  addNewRoomPanel = (event) => {
    let room_panels = this.state.room_panels,
      id;
    if (room_panels.length > 0) {
      let len = room_panels.length - 1;
      id = room_panels[len].props.id + 1;
    } else {
      id = 0;
    }
    room_panels.push(
      <AddRoom
        key={id}
        id={id}
        removePanel={this.removePanel.bind(this)}
        addRoom={this.addRoom.bind(this)}
        attendees={this.props.attendees}
        hotelinfo={this.props.hotelinfo}
        event_details={this.props.event_details}
      />
    );
    this.setState(
      {
        room_panels: room_panels,
      },
      () => {
        this.props.addRoomPanel();
      }
    );
  };
  validateRoomDetails = () => {
    var buttons = document.getElementsByClassName("add_room_submit");
    for (var i = 0; i < buttons.length; i++) buttons[i].click();
  };

  render() {
    return (
      <>
        <EventTab progress={1}>
          <div className="mt-2 mb-3">
            <HotelPopup />
            <h6 className="mb-0 ml-3 f3 b">Rooms</h6>
            <p className="mb-0 ml-3 f2 fd1">{this.props.hotel_name}</p>
            <p className="prices mb-0 ml-3 mt-3 f2">Deluxe Room:</p>
            <p className="prices mb-0 ml-3  f2">
              Single Occupancy :&nbsp;&#8377;&nbsp;
              {this.state.single_room_price.toLocaleString("en-IN")}
            </p>
            <p className="prices mb-0 ml-3 f2">
              Double Occupancy :&nbsp;&#8377;&nbsp;
              {this.state.double_room_price.toLocaleString("en-IN")}
            </p>
            {this.state.room_panels}
          </div>
          <div className="float-right mt-2">
            <Button
              variant="link"
              style={{ textDecoration: "none", color: "#3E6Ce2" }}
              onClick={this.addNewRoomPanel.bind(this)}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Room
            </Button>
          </div>
        </EventTab>
      </>
    );
  }
}
