import React from "react";
import AuthHeader from "./../common/AuthHeader";
import { Form, Col, Container, Button, Row, Card } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
// import UmoLogo from "../../resources/images/umo-logo.png";
import UmoLogo from "../../resources/images/umo-logo-new.png";
import axios from "../../server/axios";
import { NotificationManager } from "react-notifications";
export default class SetupPassword extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      validated: false,
      password: "",
      confirmPassword: "",
      submitClicked: false,
      set_token: "",
      responseType: "",
      responseMessage: "",
    };
  }
  componentDidMount() {
    this.setState({ set_token: this.props.match.params.set_token });
  }
  onChange(value) {
    // console.log("Captcha value:", value);
  }

  onInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitClicked === false) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        this.setState({ submitClicked: false });
        e.stopPropagation();
      } else {
        this.setState({ submitClicked: true });
        axios({
          method: "POST",
          url: "/resetpassword",
          data: {
            reset_token: this.state.set_token,
            password: this.state.password,
          },
        })
          .then((response) => {
            this.setState({
              submitClicked: false,
            });
            NotificationManager.success(
              "Use your new password to login.",
              "Success !",
              5000
            );
            this.props.history.push("/auth");
          })
          .catch((error) => {
            console.log(error.response);
            this.setState({ submitClicked: false });

            if (error.response) {
              if (error.response.data) {
                if (error.response.data.code === 401) {
                  this.setState({
                    responseType: "error",
                    responseMessage: "set password link has been expired.",
                  });
                }
              }
            }
          });
      }

      this.setState({ validated: true });
    }
  }
  render() {
    const { validated } = this.state;
    return (
      <>
        <AuthHeader />
        <Container className="responsive">
          <Row className="justify-content-md-center pt-5 mt-4 ">
            <Col sm={12} md={10} lg={8} xl={9}>
              <Card className="shadow responsive">
                <Row className="justify-content-md-center px-4">
                  <Col sm={12} md={10} lg={10} xl={8}>
                    <h6 className="mt-5 pt-3 f3 ">
                      New Participant?{" "}
                      <span style={{ color: "#3E6CE2" }}>
                        {" "}
                        Create an account
                      </span>
                    </h6>
                    <br />
                    <h3 className="f6 b">Setup your password</h3>

                    <br />
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={(e) => this.handleSubmit(e)}
                      className="pb-5 mt-3"
                    >
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustomEmail"
                          className="form-group"
                        >
                          <Form.Control
                            required
                            value={this.state.password}
                            onChange={this.onInputChange}
                            type="password"
                            name="password"
                            className="form-control"
                            pattern="^((?!.*[\s])(?=.*[a-z A-Z])(?=.*\d).{8,})"
                          />
                          <Form.Label className="form-control-placeholder">
                            New Password
                          </Form.Label>

                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid New Password
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustomPasword"
                          className="form-group"
                        >
                          <Form.Control
                            name="confirmPassword"
                            value={this.state.confirmPassword}
                            onChange={this.onInputChange}
                            isValid={
                              this.state.password ===
                                this.state.confirmPassword &&
                              /^((?!.*[\s])(?=.*[a-z A-Z])(?=.*\d).{8,})/.test(
                                this.state.confirmPassword
                              )
                            }
                            isInvalid={
                              this.state.password !== this.state.confirmPassword
                            }
                            required
                            type="password"
                            className="form-control"
                            pattern="^((?!.*[\s])(?=.*[a-z A-Z])(?=.*\d).{8,})"
                          />
                          <Form.Label className="form-control-placeholder">
                            Confirm New Password
                          </Form.Label>
                          {this.state.password !==
                          this.state.confirmPassword ? (
                            <Form.Control.Feedback type="invalid">
                              New password and confirm password should be same
                            </Form.Control.Feedback>
                          ) : (
                            <Form.Control.Feedback type="invalid">
                              Minimum of 8 charecters, one letter and one number
                            </Form.Control.Feedback>
                          )}

                          <small className="fd1 f1 mt-0">
                            Minimum of 8 charecters, one letter and one number
                          </small>
                        </Form.Group>
                      </Form.Row>
                      <br />
                      {/* <div>
                        <div className="g-recaptcha" data-sitekey="6LfgM_QhAAAAAOfS_l0WVG9cq1fjwfleasi66ni9" />
                      </div> */}

                      {/* <ReCAPTCHA sitekey="6LfgM_QhAAAAAOfS_l0WVG9cq1fjwfleasi66ni9" onChange={this.onChange} /> */}
                      <br />
                      <Button
                        onClick={this.onLoginClick}
                        className="auth-primary f3 b"
                        size="lg"
                        type="submit"
                        block
                      >
                        CREATE AN ACCOUNT
                      </Button>
                      <small className="mt-2 f1 fd1">
                        By creating an account you agree to the &nbsp;
                        <a
                          target="_blank"
                          href="https://2023.ux-india.org/privacy-policy/"
                        >
                          Customer Privacy Policy
                        </a>
                        &nbsp;and&nbsp;
                        <a
                          target="_blank"
                          href="https://2023.ux-india.org/terms/"
                        >
                          Fair User Policy
                        </a>
                      </small>
                      <br />
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-md-center pt-0 mt-0">
            <Col sm={12} md={10} lg={8} xl={9}>
              <Row className="justify-content-md-center pt-0 mt-0">
                <Col sm={12} md={10} lg={10} xl={8}>
                  <div className="auth-powered">
                    <small>Powered by</small>
                    <br />
                    <img
                      src={UmoLogo}
                      alt="umo_logo"
                      style={{ width: 100 + "px" }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
