import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Panel from "../common/Panel";
import Ticket from "../common/Ticket";

export default class TicketInfo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      participants: 1,
    }
  }
  selectedParticipants = (ticket_id, participants) => {
    this.setState({ ticket_id: ticket_id, participants: parseInt(participants) });
  }
  render() {
    let total_active_tickets = 0;
    let active_ticket_cnt = 0, ticket_id = 0;
    this.props.tickets[0].tickets.forEach(el => {
      if (el.ticket_status === 1) {

        total_active_tickets++;
      }
    });
let j = this.props.tickets[0].tickets
console.log("1st",j);
j = j.sort((a, b) => {
  return b.price - a.price;
});

    const tickets = j.map((el, index) => {
      if (el.ticket_status === 1 && el.current_available_count > 0) {
        ticket_id = el.ticket_id;
        active_ticket_cnt++;
        console.log(el.price);
        console.log(typeof(el));
      }
      return (<Ticket key={index} ticket_details={el} package_id={this.props.package_id} event_id={this.props.event_id} ticket_id={ticket_id} total_active_tickets={total_active_tickets} participants={this.state.participants} selectedParticipants={this.selectedParticipants.bind(this)} package_name={this.props.tickets[0].package_name} />);
    });
    let disabled = true;
    if (active_ticket_cnt > 0) {
      disabled = false;
    }
    const { event_id, package_id } = this.props;
    return (

      <div className="mt-5">
        <Panel title="TICKET INFO" hideCollapsable={true}>
          <div className="ticket-padding">
            {total_active_tickets > 0 ?
              (<>
                <p className="f3 b mb-0">Select No. of participants</p>
                {tickets}
                {total_active_tickets < 2 && <div className="float-right mt-4" >
                  <NavLink to={"/select-participants/" + event_id + '/' + package_id + '/' + ticket_id + '/' + this.state.participants}>
                    <Button variant="primary" className="btn-custom f3 b" disabled={disabled}>
                      GET STARTED
                </Button>
                  </NavLink>
                </div>}
              </>) :
              (<p className="text-center">No Tickets Found, Check back Later!</p>)}
          </div>
        </Panel>
      </div>
    );
  }
}
